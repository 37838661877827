<template>
  <l-map
    ref="map"
    class="map-size mt-1"
    :options="{ zoomControl: false, dragging: false, scrollWheelZoom: false }"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="marker" :icon="iconFlag"></l-marker>
    <l-marker
      v-for="item in reportData.developerPrograms"
      :key="item.id"
      :lat-lng="[item.location.latitude, item.location.longitude]"
      :icon="
        item.total_available_lots > 0 ? iconBuildingBlue : iconBuildingOrange
      "
      ref="markers"
    >
      <l-tooltip :content="item.name" />
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Custom marker for leaflet map
import "@fortawesome/fontawesome-free/js/all.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

import { ref } from "@vue/composition-api";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution: '&copy; <a href="http://osm.org/copyright"></a>',
      iconBuildingBlue: L.AwesomeMarkers.icon({
        icon: "building",
        markerColor: "blue",
        iconColor: "white",
        prefix: "fa",
      }),
      iconBuildingOrange: L.AwesomeMarkers.icon({
        icon: "building",
        markerColor: "orange",
        iconColor: "white",
        prefix: "fa",
      }),
      iconFlag: L.AwesomeMarkers.icon({
        icon: "flag",
        markerColor: "green",
        iconColor: "white",
        prefix: "fa",
      }),
    };
  },
  setup(props) {
    let marker = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );
    let center = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );

    return {
      marker,
      center,
    };
  },
  mounted() {
    if (this.reportData.developerPrograms.length > 0) {
      const bounds = this.reportData.developerPrograms.map((m) => {
        return [m.location.latitude, m.location.longitude];
      });
      bounds.push([
        this.reportData.center.latitude,
        this.reportData.center.longitude,
      ]);
      this.$refs.map.mapObject.fitBounds(bounds);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.map-size {
  height: 30rem !important;
}
</style>
