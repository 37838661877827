<template>
  <div v-if="reportData.demographyByPeriode.length > 0">
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="UsersIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Sociodémographie</h4>
          <span
            >Dans la section 'Sociodémographie', vous trouverez les indicateurs
            nécessaires pour comprendre la composition de la population du
            secteur.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-19">
      <b-col md="12">
        <people-division-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
    <b-row id="item-20">
      <b-col md="12">
        <population-trend-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
    <b-row id="item-21">
      <b-col md="12">
        <household-division-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import PopulationTrendCard from "./population-trend/PopulationTrendCard";
import PeopleDivisionCard from "./people-division/PeopleDivisionCard";
import HouseholdDivisionCard from "./household-division/HouseholdDivisionCard";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    PopulationTrendCard,
    PeopleDivisionCard,
    HouseholdDivisionCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
