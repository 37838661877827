<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { currency, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    prixm2ByPeriode: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: false,
    },
  },
  data() {
    const xAxisData = [];
    const newLodgmentWithParkingData = [];
    const newLodgmentWithoutParkingData = [];
    const oldLodgmentData = [];

    this.prixm2ByPeriode.data.forEach((element) => {
      xAxisData.push(formatPeriodQuarter(element.period));
      element.price_meter_new_lodgment_with_parking !== 0
        ? newLodgmentWithParkingData.push(
            element.price_meter_new_lodgment_with_parking
          )
        : newLodgmentWithParkingData.push(null);
      element.price_meter_new_lodgment_without_parking !== 0
        ? newLodgmentWithoutParkingData.push(
            element.price_meter_new_lodgment_without_parking
          )
        : newLodgmentWithoutParkingData.push(null);
      element.price_meter_old_lodgment !== 0
        ? oldLodgmentData.push(element.price_meter_old_lodgment)
        : oldLodgmentData.push(null);
    });

    const series = [
      {
        name: "Prix/m² moyen logement neuf à proximité (avec parking)",
        type: "line",
        showSymbol: true,
        lineStyle: {
          width: 3,
        },
        data: newLodgmentWithParkingData,
      },
      {
        name: "Prix/m² moyen logement neuf à proximité (sans parking)",
        type: "line",
        showSymbol: true,
        lineStyle: {
          width: 3,
        },
        data: newLodgmentWithoutParkingData,
      },
      {
        name: "Prix/m² moyen logement ancien à proximité",
        type: "line",
        showSymbol: true,
        lineStyle: {
          width: 3,
        },
        data: oldLodgmentData,
      },
    ];

    if (this.program) {
      series.push({
        name: "Prix/m² moyen programme sélectionné (avec parking)",
        type: "line",
        showSymbol: true,
        symbol: "arrow",
        symbolSize: 20,
        lineStyle: {
          width: 3,
        },
        data: [
          null,
          null,
          null,
          null,
          null,
          this.program.price.full_vat_price_per_meter_parking_included,
        ],
      });
      series.push({
        name: "Prix/m² moyen programme sélectionné (sans parking)",
        type: "line",
        showSymbol: true,
        symbol: "arrow",
        symbolSize: 20,
        lineStyle: {
          width: 3,
        },
        data: [
          null,
          null,
          null,
          null,
          null,
          this.program.price.full_vat_price_per_meter_parking_excepted,
        ],
      });
    }

    return {
      option: {
        xAxisData,
        series,
        formatter: (params) => {
          var tooltip = `
                Année: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? currency(params[0].value) : "-"
          }`;
          if (params.length >= 2) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
                params[1].value ? currency(params[1].value) : "-"
              }`;
          }
          if (params.length >= 3) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[2].color ? params[2].color : null
                }"></span>${params[2].seriesName}: ${
                params[2].value ? currency(params[2].value) : "-"
              }`;
          }
          if (params.length >= 4) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[3].color ? params[3].color : null
                }"></span>${params[3].seriesName}: ${
                params[3].value ? currency(params[3].value) : "-"
              }`;
          }
          if (params.length >= 5) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[4].color ? params[4].color : null
                }"></span>${params[4].seriesName}: ${
                params[4].value ? currency(params[4].value) : "-"
              }`;
          }
          return tooltip;
        },
      },
    };
  },
  methods: {
    currency,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
