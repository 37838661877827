<template>
  <div>
    <app-echart-bar :option-data="option" class="type-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { percentage } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const series = [];
    const selectedTypos = [];
    const dataMin = [];
    const dataAvg = [];
    const dataMax = [];

    const typos = ["t1", "t2", "t3", "t4", "t5"];

    typos.forEach((type) => {
      if (
        this.program[type].living_area.min &&
        this.program[type].full_vat_price.price_parking_excepted.min
      ) {
        selectedTypos.push(type);
      }
    });

    selectedTypos.forEach((type) => {
      dataMin.push(
        ((this.ventesByPeriode.pinel.rent *
          this.program[type].living_area.min *
          12) /
          this.program[type].full_vat_price.price_parking_excepted.min) *
          100
      );

      dataAvg.push(
        ((this.ventesByPeriode.pinel.rent *
          this.program[type].living_area.avg *
          12) /
          this.program[type].full_vat_price.price_parking_excepted.avg) *
          100
      );

      dataMax.push(
        ((this.ventesByPeriode.pinel.rent *
          this.program[type].living_area.max *
          12) /
          this.program[type].full_vat_price.price_parking_excepted.max) *
          100
      );
    });

    series.push({
      name: "Rendement locatif Pinel évalué sur surface/prix minimum",
      type: "bar",
      data: dataMin,
      barMaxWidth: "13%",
      barMinWidth: "10px",
    });
    series.push({
      name: "Rendement locatif Pinel évalué sur surface/prix moyen",
      type: "bar",
      data: dataAvg,
      barMaxWidth: "13%",
      barMinWidth: "10px",
    });
    series.push({
      name: "Rendement locatif Pinel évalué sur surface/prix maximum",
      type: "bar",
      data: dataMax,
      barMaxWidth: "13%",
      barMinWidth: "10px",
    });

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: selectedTypos,
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              type: "dashed",
              color: "#B8B8B8",
            },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        formatter: (params) => {
          var tooltip = `
                Type: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? params[0].value.toFixed(2) + "%" : "-"
          }`;
          if (params.length >= 2) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
                params[1].value ? params[1].value.toFixed(2) + "%" : "-"
              }`;
          }
          if (params.length >= 3) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[2].color ? params[2].color : null
                }"></span>${params[2].seriesName}: ${
                params[2].value ? params[2].value.toFixed(2) + "%" : "-"
              }`;
          }
          if (params.length >= 4) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[3].color ? params[3].color : null
                }"></span>${params[3].seriesName}: ${
                params[3].value ? params[3].value.toFixed(2) + "%" : "-"
              }`;
          }
          if (params.length >= 5) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[4].color ? params[4].color : null
                }"></span>${params[4].seriesName}: ${
                params[4].value ? params[4].value.toFixed(2) + "%" : "-"
              }`;
          }
          return tooltip;
        },
        series,
      },
    };
  },
  methods: {
    percentage,
  },
};
</script>

<style lang="scss" scoped>
.type-chart {
  width: 100%;
  height: 21rem;
}
</style>
