<template>
  <div class="mt-1">
    <b-table
      :items="items"
      :fields="fields"
      small
      responsive
      :tbody-tr-class="rowClass"
    >
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar size="45" :src="data.item.brochure_url" />
          </template>
          <b-link class="font-weight-bold d-block">
            {{ data.item.name }}
            <b-badge
              pill
              :variant="
                data.item.total_available_lots > 0
                  ? 'light-primary'
                  : 'light-warning'
              "
              class="ml-1"
            >
              {{ data.item.total_available_lots > 0 ? "en cours" : "terminé" }}
            </b-badge>
          </b-link>
          <small class="text-muted">{{ data.item.promoter_name }} - </small>
          <small class="text-primary">{{ data.item.distance }}m</small>
        </b-media>
      </template>

      <template #cell(types)="data">
        <span>{{ evalBetweenType(data.item) }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BMedia, BLink, BAvatar, BBadge } from "bootstrap-vue";
import {
  evalBetweenType,
  formatReverseDate,
  currency,
} from "@core/utils/filter";

export default {
  components: {
    BTable,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Programme",
        },
        {
          key: "types",
          label: "Typologies",
        },
        {
          key: "sale_date",
          label: "Date vente",
          formatter: (value) => `${this.formatReverseDate(value)}`,
        },
        {
          key: "delivery_date",
          label: "Date livraison",
          formatter: (value) => `${this.formatReverseDate(value)}`,
        },
        {
          key: "price.full_vat_price_per_meter_parking_excepted",
          label: "Prix/m² moyen (parking exclus)",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "price.full_vat_price_per_meter_parking_included",
          label: "Prix/m² moyen (parking inclus)",
          formatter: (value) => `${this.currency(value)}`,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.reportData.ecosystemPrograms;
    },
  },
  methods: {
    evalBetweenType,
    formatReverseDate,
    currency,
    rowClass(item) {
      const colorClass = "table-primary";
      if (item.selected) {
        return colorClass;
      }
    },
  },
};
</script>
