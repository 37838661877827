<template>
  <b-card title="Mobilité">
    <b-row>
      <b-col cols="4">
        <mobility-details :reportData="reportData" />
      </b-col>
      <b-col cols="8">
        <mobility-map :reportData="reportData" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import MobilityMap from "./mobility-elements/MobilityMap";
import MobilityDetails from "./mobility-elements/MobilityDetails";

export default {
  components: {
    BCard,
    BRow,
    BCol,

    // Components Vue
    MobilityMap,
    MobilityDetails,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
