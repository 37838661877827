<template>
  <section v-if="reportData.demographyByPeriode.length > 0">
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="SunIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Cadre de vie</h4>
          <span>Commodités, transports, services de proximité..</span>
        </div>
      </b-col>
    </b-row>

    <b-row id="item-22">
      <b-col md="12">
        <mobility-card :reportData="reportData" />
      </b-col>
    </b-row>
    <b-row id="item-23">
      <b-col md="12">
        <healthcare-card :reportData="reportData" />
      </b-col>
    </b-row>
    <b-row id="item-24">
      <b-col md="12">
        <education-card :reportData="reportData" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import MobilityCard from "./mobility/MobilityCard";
import HealthcareCard from "./healthcare/HealthcareCard";
import EducationCard from "./education/EducationCard";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    MobilityCard,
    HealthcareCard,
    EducationCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
