<template>
  <section>
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="TargetIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Programme</h4>
          <span
            >Dans la section 'Programme', vous trouverez toutes les informations
            nécessaires sur le programme sélectionné.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-0">
      <b-col md="12" v-if="reportData.ventesByPeriode[0]">
        <b-card>
          <program-details
            :program="reportData.program"
            :ventesByPeriode="reportData.ventesByPeriode[0]"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row id="item-01">
      <b-col md="12" v-if="reportData.ventesByPeriode[0]">
        <rental-pinel-yield-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-1">
      <b-col md="12">
        <developer-programs-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BAvatar } from "bootstrap-vue";
import ProgramDetails from "./description/ProgramDetails.vue";
import DeveloperProgramsCard from "./developer-programs/DeveloperProgramsCard";
import RentalPinelYieldCard from "./rental-pinel-yield/RentalPinelYieldCard";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,

    ProgramDetails,
    DeveloperProgramsCard,
    RentalPinelYieldCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
