<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Rendement locatif Pinel</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre le rendement locatif Pinel moyen, pour la surface et prix minimum/maximum du programme sélectionné pour chaque typologie ."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row v-if="!checkEmptyProgram()">
      <b-col xl="12">
        <rental-pinel-yield-chart
          :program="reportData.program"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <rental-pinel-yield-array
          :program="reportData.program"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.rentalPinelYield.enable"
          :commentField="reportData.comments.rentalPinelYield.value"
          v-on:update:comment="
            reportData.comments.rentalPinelYield.value = $event
          "
          v-on:update:enable="
            reportData.comments.rentalPinelYield.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center text-center mb-1 mt-2" v-else>
      <b-col>
        <feather-icon icon="LoaderIcon" size="30" />
        <h4 class="mt-2 mb-1">Des données sont manquantes</h4>
        <p class="card-text">
          Certaines données nécessaires aux calculs sont indisponibles.
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VBTooltip, BCard, BRow, BCol, BAvatar } from "bootstrap-vue";
import RentalPinelYieldChart from "./elements/RentalPinelYieldChart";
import RentalPinelYieldArray from "./elements/RentalPinelYieldArray";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    RentalPinelYieldChart,
    RentalPinelYieldArray,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkEmptyProgram() {
      const selectedTypos = [];
      const typos = ["t1", "t2", "t3", "t4", "t5"];

      typos.forEach((type) => {
        if (
          this.reportData.program[type].living_area.min &&
          this.reportData.program[type].full_vat_price.price_parking_excepted
            .min
        ) {
          selectedTypos.push(type);
        }
      });

      if (selectedTypos.length > 0) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
