<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Tendance</h4>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur compare l’évolution des prix moyens de ventes au m² (prix à la vente) et des prix de l’offre au m² (prix à la commercialisation) sur les 4 dernières années sur le secteur du programme ou adresse sélectionné."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <sales-trend-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      </b-col>
      <b-col xl="12">
        <sales-trend-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.salesTrend.enable"
          :commentField="reportData.comments.salesTrend.value"
          v-on:update:comment="reportData.comments.salesTrend.value = $event"
          v-on:update:enable="reportData.comments.salesTrend.enable = $event"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import SalesTrendChart from "./sales-trend-elements/SalesTrendChart";
import SalesTrendArray from "./sales-trend-elements/SalesTrendArray";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    SalesTrendArray,
    SalesTrendChart,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
