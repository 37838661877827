<template>
  <div class="mt-1">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell()="data">
        <div v-if="data.value.val">
          {{ data.value.val }}
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Yanport</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { currency, percentage, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    rentTrend: {
      type: Object,
      required: true,
    },
    prixm2ByPeriode: {
      type: Object,
      required: true,
    },
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const items = [];

    var i = 1;
    this.prixm2ByPeriode.data.forEach((element) => {
      if (element.price_meter_new_lodgment_without_parking > 0) {
        const rentalYield =
          (this.rentTrend.data.find(
            (e) => e.year === element.period.slice(0, 4)
          ).rent_meter *
            12) /
          element.price_meter_new_lodgment_without_parking;

        const rentalPinelYield =
          (this.ventesByPeriode.pinel.rent * 12) /
          element.price_meter_new_lodgment_without_parking;

        items.push({
          year: formatPeriodQuarter(element.period),
          rent_meter: {
            val:
              Number(
                this.rentTrend.data.find(
                  (e) => e.year === element.period.slice(0, 4)
                ).rent_meter
              ).toFixed(2) + " €",
          },
          rent_meter_pinel: {
            val:
              this.prixm2ByPeriode.data.length === i
                ? this.ventesByPeriode.pinel.rent + "€"
                : "-",
          },
          rent_meter_year: {
            val: currency(
              this.rentTrend.data.find(
                (e) => e.year === element.period.slice(0, 4)
              ).rent_meter * 12
            ),
          },
          rent_meter_pinel_year: {
            val:
              this.prixm2ByPeriode.data.length === i
                ? currency(this.ventesByPeriode.pinel.rent * 12)
                : "-",
          },
          price_meter: {
            val: currency(element.price_meter_new_lodgment_without_parking),
          },
          rental_yield: {
            val: percentage(rentalYield),
          },
          rental_yield_pinel: {
            val:
              this.prixm2ByPeriode.data.length === i
                ? percentage(rentalPinelYield)
                : "-",
          },
        });
      }
      i++;
    });

    return {
      fields: [
        {
          key: "year",
          label: "Année",
        },
        {
          key: "rent_meter",
          label: "Loyer moyen au m²",
        },
        {
          key: "rent_meter_pinel",
          label: "Loyer Pinel",
        },
        {
          key: "rent_meter_year",
          label: "Loyer moyen au m² sur un an",
        },
        {
          key: "rent_meter_pinel_year",
          label: "Loyer Pinel au m² sur un an",
        },
        {
          key: "price_meter",
          label: "Prix moyen au m²",
        },
        {
          key: "rental_yield",
          label: "Rendement locatif brut moyen",
        },
        {
          key: "rental_yield_pinel",
          label: "Rendement locatif Pinel",
        },
      ],
      items,
    };
  },
  methods: {
    currency,
  },
};
</script>
