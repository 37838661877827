<template>
  <div class="mr-1 height-report-fixed">
    <b-overlay
      :show="reportData.isBusy || isGeneratingPdf"
      no-wrap
      opacity="0.4"
      rounded="lg"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" />
          <b-spinner type="grow" variant="primary" />
          <b-spinner small type="grow" variant="dark" />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Chargement wait...</span>
        </div>
      </template>
    </b-overlay>

    <!-- <b-modal
      id="modal-settings"
      ok-only
      ok-title="Retour"
      centered
      size="md"
      title="Paramètres du rapport"
    >
      <settings-modal />
    </b-modal> -->

    <b-container fluid class="h-100">
      <b-row class="mt-1 h-100">
        <b-col cols="2">
          <b-row class="mx-50">
            <b-col cols="3" class="px-0 text-center">
              <b-avatar
                v-if="reportData.program"
                size="45"
                :src="reportData.program.brochure_url"
              />
            </b-col>
            <b-col cols="9" class="px-0">
              <b-container>
                <h4>{{ reportData.program.name }}</h4>
                <span class="text-primary">
                  <feather-icon icon="MapPinIcon" />
                  {{
                    reportData.program.location
                      ? reportData.program.location.sector_name
                      : null
                  }}
                </span>
              </b-container>
            </b-col>
          </b-row>

          <!-- <div class="text-center mt-2 mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="generateReport"
              :disabled="isGeneratingPdf"
            >
              <div>
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span>Télécharger</span>
              </div>
            </b-button>
          </div> -->

          <b-navbar
            v-b-scrollspy:scrollspy-nested.300
            class="flex-column mt-2"
            :key="tabIndex"
          >
            <b-nav pills vertical>
              <b-nav-item
                v-for="section in selectedTabInfo.sections"
                :key="section.ref"
                :href="`#${section.ref}`"
                link-classes="nav-link-custom"
                >{{ section.title }}</b-nav-item
              >
            </b-nav>
          </b-navbar>
        </b-col>

        <b-col cols="10" class="h-100">
          <b-tabs class="h-100" content-class="h-100" v-model="tabIndex">
            <b-tab lazy active>
              <template #title>
                <feather-icon icon="TargetIcon" />
                <span>Programme</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <program-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="TrendingUpIcon" />
                <span>Attractivité</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <attractivity-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="ShoppingBagIcon" />
                <span>Immobilier neuf</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <business-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="HomeIcon" />
                <span>Logements</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <logement-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="DollarSignIcon" />
                <span>Economie</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <economy-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="UsersIcon" />
                <span>Sociodémographie</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <sociodemo-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="SunIcon" />
                <span>Cadre de vie</span>
              </template>

              <div id="scrollspy-nested" class="scrollspy">
                <div class="mx-1">
                  <cadredevie-tab
                    :reportData="reportData"
                    @update-report="onUpdateReport"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="760px"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
      v-if="reportData.isBusy === false"
    >
      <pdf-content slot="pdf-content" :reportData="reportData" class="ml-2" />
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BAvatar,
  /* BButton, */
  VBScrollspy,
  BNav,
  BNavbar,
  BNavItem,
  BSpinner,
  BOverlay,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { useRouter } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import VueHtml2pdf from "vue-html2pdf";

// Module Situation API Client
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import decimmoStoreModule from "../decimmoStoreModule";

// Customs View Elements
import PdfContent from "./PdfContent";
import ProgramTab from "./program/ProgramTab";
import AttractivityTab from "./attractivity/AttractivityTab";
import BusinessTab from "./business/BusinessTab";
import LogementTab from "./logement/LogementTab";
import EconomyTab from "./economy/EconomyTab";
import SociodemoTab from "./sociodemography/SociodemoTab";
import CadredevieTab from "./cadredevie/CadredevieTab";

import { useReportData } from "./reportData";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BAvatar,
    /* BButton, */
    BNav,
    BNavbar,
    BNavItem,
    BSpinner,
    BOverlay,
    BTabs,
    BTab,

    VueHtml2pdf,

    // Vue components
    PdfContent,
    ProgramTab,
    AttractivityTab,
    BusinessTab,
    LogementTab,
    EconomyTab,
    SociodemoTab,
    CadredevieTab,
  },
  directives: {
    Ripple,
    "b-scrollspy": VBScrollspy,
  },
  data() {
    return {
      tabIndex: 0,
      tabsInfo: [
        {
          sections: [
            { title: "Détails du programme", ref: "item-0" },
            { title: "Rendement locatif Pinel", ref: "item-01" },
            { title: "Offre du promoteur", ref: "item-1" },
          ],
        },
        {
          sections: [
            { title: "Prix m² par période", ref: "item-2" },
            { title: "Prix m² par typologie", ref: "item-3" },
            { title: "Loyer m²", ref: "item-4" },
            { title: "Rendement locatif", ref: "item-5" },
            { title: "Autres programmes", ref: "item-6" },
          ],
        },
        {
          sections: [
            { title: "Volumes", ref: "item-7" },
            { title: "Tendance", ref: "item-8" },
            { title: "Ecoulement", ref: "item-9" },
            { title: "Investissements", ref: "item-10" },
          ],
        },
        {
          sections: [
            { title: "Répartition des logements", ref: "item-11" },
            {
              title: "Répartition des résidences principales par type",
              ref: "item-12",
            },
            {
              title: "Période d'achèvement des résidences principales",
              ref: "item-13",
            },
            { title: "Lieu de résidence un an auparavant", ref: "item-14" },
            {
              title: "Ancienneté d'emménagement dans la résidence principale",
              ref: "item-15",
            },
            {
              title: "Occupation des logements",
              ref: "item-16",
            },
          ],
        },
        {
          sections: [
            { title: "Catégories socioprofessionelles", ref: "item-17" },
            { title: "Emploi & Chômage", ref: "item-18" },
          ],
        },
        {
          sections: [
            { title: "Répartition de la population", ref: "item-19" },
            { title: "Evolution de la population", ref: "item-20" },
            { title: "Composition des ménages", ref: "item-21" },
          ],
        },
        {
          sections: [
            { title: "Mobilité", ref: "item-22" },
            { title: "Santé", ref: "item-23" },
            { title: "Éducation", ref: "item-24" },
          ],
        },
      ],
      selectedTabInfo: {
        sections: [
          { title: "Détails du programme", ref: "item-0" },
          { title: "Rendement locatif Pinel", ref: "item-01" },
          { title: "Offre du promoteur", ref: "item-1" },
        ],
      },
      isGeneratingPdf: false,
      htmlToPdfOptions: {
        margin: [5, 2, 2, 2],
        filename: "Rapport_CadreDeVie_Décision_Immo.pdf",
        enableLinks: false,
        html2canvas: {
          useCORS: true,
        },
      },
    };
  },
  watch: {
    tabIndex(val) {
      this.selectedTabInfo = this.tabsInfo[val];
    },
  },
  setup() {
    const { route } = useRouter();
    const { reportData, updateReport, fetchSavedReportData } = useReportData();

    const DECIMMO_APP_STORE_MODULE_NAME = "app-decimmo";

    // Register module
    if (!store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
      store.registerModule(DECIMMO_APP_STORE_MODULE_NAME, decimmoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DECIMMO_APP_STORE_MODULE_NAME);
    });

    fetchSavedReportData(route.value.query.id);

    return {
      reportData,

      updateReport,
    };
  },
  methods: {
    onUpdateReport() {
      this.updateReport();
    },
    // PDF
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(9);
            pdf.setTextColor(150);
            pdf.text(
              `${i} / ${totalPages}`,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 4
            );
          }
        })
        .save()
        .then(() => {
          this.isGeneratingPdf = false;
        });
    },
    generateReport() {
      this.isGeneratingPdf = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";

.nav-link-custom {
  justify-content: left !important;
  padding: 0.836rem 1.5rem !important;
}

.scrollspy {
  position: absolute;
  width: 98%;
  height: 100%;
  overflow-y: auto;
}

.height-report-fixed {
  position: absolute;
  height: 76%;
  width: 99%;
}
</style>
