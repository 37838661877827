<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive> </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee
        {{
          this.demographyByPeriode.data[
            this.demographyByPeriode.data.length - 1
          ].year
        }}</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { bigNumberFormatter, crossProduct } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          label: "Période",
        },
        {
          key: "appartment",
          label: "Appartement",
        },
        {
          key: "house",
          label: "Maison",
        },
        {
          key: "total",
          label: "Total",
        },
        {
          key: "rate",
          label: "Répartition",
        },
      ],
    };
  },
  computed: {
    items() {
      const data =
        this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
          .lodgment.division.main_lodgment_building_time_division;

      const total =
        data.before_1919.appartment +
        data.before_1919.house +
        data.from_1919_to_1945.appartment +
        data.from_1919_to_1945.house +
        data.from_1946_to_1970.appartment +
        data.from_1946_to_1970.house +
        data.from_1971_to_1990.appartment +
        data.from_1971_to_1990.house +
        data.from_1991_to_2005.appartment +
        data.from_1991_to_2005.house +
        data.from_2006_to_2015.appartment +
        data.from_2006_to_2015.house;

      return [
        {
          label: "Avant 1919",
          appartment: bigNumberFormatter(data.before_1919.appartment),
          house: bigNumberFormatter(data.before_1919.house),
          total: bigNumberFormatter(
            data.before_1919.appartment + data.before_1919.house
          ),
          rate:
            crossProduct(
              data.before_1919.appartment + data.before_1919.house,
              total
            ) + "%",
        },
        {
          label: "De 1919 à 1945",
          appartment: bigNumberFormatter(data.from_1919_to_1945.appartment),
          house: bigNumberFormatter(data.from_1919_to_1945.house),
          total: bigNumberFormatter(
            data.from_1919_to_1945.appartment + data.from_1919_to_1945.house
          ),
          rate:
            crossProduct(
              data.from_1919_to_1945.appartment + data.from_1919_to_1945.house,
              total
            ) + "%",
        },
        {
          label: "De 1946 à 1970",
          appartment: bigNumberFormatter(data.from_1946_to_1970.appartment),
          house: bigNumberFormatter(data.from_1946_to_1970.house),
          total: bigNumberFormatter(
            data.from_1946_to_1970.appartment + data.from_1946_to_1970.house
          ),
          rate:
            crossProduct(
              data.from_1946_to_1970.appartment + data.from_1946_to_1970.house,
              total
            ) + "%",
        },
        {
          label: "De 1971 à 1990",
          appartment: bigNumberFormatter(data.from_1971_to_1990.appartment),
          house: bigNumberFormatter(data.from_1971_to_1990.house),
          total: bigNumberFormatter(
            data.from_1971_to_1990.appartment + data.from_1971_to_1990.house
          ),
          rate:
            crossProduct(
              data.from_1971_to_1990.appartment + data.from_1971_to_1990.house,
              total
            ) + "%",
        },
        {
          label: "De 1991 à 2005",
          appartment: bigNumberFormatter(data.from_1991_to_2005.appartment),
          house: bigNumberFormatter(data.from_1991_to_2005.house),
          total: bigNumberFormatter(
            data.from_1991_to_2005.appartment + data.from_1991_to_2005.house
          ),
          rate:
            crossProduct(
              data.from_1991_to_2005.appartment + data.from_1991_to_2005.house,
              total
            ) + "%",
        },
        {
          label: "De 2006 à 2015",
          appartment: bigNumberFormatter(data.from_2006_to_2015.appartment),
          house: bigNumberFormatter(data.from_2006_to_2015.house),
          total: bigNumberFormatter(
            data.from_2006_to_2015.appartment + data.from_2006_to_2015.house
          ),
          rate:
            crossProduct(
              data.from_2006_to_2015.appartment + data.from_2006_to_2015.house,
              total
            ) + "%",
        },
      ];
    },
  },
};
</script>
