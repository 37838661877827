<template>
  <div>
    <b-form-checkbox v-model="enabled" name="check-button" switch inline>
      Ajouter un commentaire
    </b-form-checkbox>

    <div v-if="enabled" class="mt-1">
      <b-form-textarea
        v-model="value"
        placeholder="Mon interprétation ..."
        rows="5"
        class="char-textarea"
        :class="value.length >= maxChar ? 'text-danger' : ''"
      />
      <small
        class="textarea-counter-value float-right"
        :class="value.length >= maxChar ? 'bg-danger' : ''"
      >
        <span class="char-count">{{ value.length }}</span> / {{ maxChar }}
      </small>

      <b-row>
        <b-col class="text-right mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="updateReport()"
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            <span>Enregistrer</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    maxChar: 1024,
  }),
  props: {
    enable: {
      type: Boolean,
      required: true,
    },
    commentField: {
      type: String,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.commentField;
      },
      set(val) {
        this.$emit("update:comment", val);
      },
    },
    enabled: {
      get() {
        return this.enable;
      },
      set(val) {
        this.$emit("update:enable", val);
      },
    },
  },
  methods: {
    updateReport() {
      this.$emit("update-report");
    },
  },
};
</script>
