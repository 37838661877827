<template>
  <section class="mx-2 mb-2">
    <div v-if="nurseries.length > 0" class="mb-1">
      <h5>Crêche</h5>
      <div v-for="nursery in nurseries" :key="nursery.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <i class="fas fa-baby fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ nursery.name }}</h6>
            <small>à {{ nursery.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="primarySchools.length > 0" class="mb-1">
      <h5>Primaire</h5>
      <div
        v-for="primarySchool in primarySchools"
        :key="primarySchool.id"
        class="my-50"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-warning">
              <i class="fas fa-school fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ primarySchool.name }}</h6>
            <small>à {{ primarySchool.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="secondarySchools.length > 0" class="mb-1">
      <h5>Secondaire</h5>
      <div
        v-for="secondarySchool in secondarySchools"
        :key="secondarySchool.id"
        class="my-50"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <i class="fas fa-graduation-cap fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ secondarySchool.name }}</h6>
            <small>à {{ secondarySchool.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="superiorSchools.length > 0" class="mb-1">
      <h5>Supérieur</h5>
      <div
        v-for="superiorSchool in superiorSchools"
        :key="superiorSchool.id"
        class="my-50"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-danger">
              <i class="fas fa-university fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ superiorSchool.name }}</h6>
            <small>à {{ superiorSchool.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </section>
</template>

<script>
import { BMediaBody, BMedia, BMediaAside, BAvatar } from "bootstrap-vue";

// leaflet
import L from "leaflet";
import "@fortawesome/fontawesome-free/js/all.js";

export default {
  components: {
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "reportData.nurseries"(val) {
      this.nurseries = this.formatOverpassResp(val, 1);
    },
    "reportData.primarySchools"(val) {
      this.primarySchools = this.formatOverpassResp(val, 2);
    },
    "reportData.secondarySchools"(val) {
      this.secondarySchools = this.formatOverpassResp(val, 2);
    },
    "reportData.superiorSchools"(val) {
      this.superiorSchools = this.formatOverpassResp(val, 1);
    },
  },
  data() {
    return {
      nurseries: [],
      primarySchools: [],
      secondarySchools: [],
      superiorSchools: [],
    };
  },
  methods: {
    formatOverpassResp(array, limit) {
      return (
        Array.from(
          // distinct by name (remove double)
          new Set(array.map((s) => s.tags["name"]))
        )
          .map((name) => {
            // rebuild the object back
            const found = array.find((s) => s.tags["name"] === name);
            return {
              id: found.id,
              name: name,
              latlng: L.latLng(found.lat, found.lon),
              distance: L.latLng(found.lat, found.lon)
                .distanceTo(
                  L.latLng(
                    this.reportData.center.latitude,
                    this.reportData.center.longitude
                  )
                )
                .toFixed(0),
            };
          })
          // sort by distance asc
          .sort(function (a, b) {
            return a.distance - b.distance;
          })
          // keep only stop with distance < 1.5km
          .filter((elem) => elem.distance < 1500)
          // keep only 2 first result
          .slice(0, limit)
      );
    },
  },
};
</script>
