<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive> </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { currency, percentage } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          label: "Typologie",
        },
        {
          key: "rentPinelMeter",
          label: "Loyer Pinel au m²",
          formatter: (value) => `${value} €`,
        },
        {
          key: "rentPinelMin",
          label: "Loyer Pinel minimum",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "rentPinelMoy",
          label: "Loyer Pinel moyen",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "rentPinelMax",
          label: "Loyer Pinel maximum",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "yieldPinelMin",
          label: "Rendement locatif Pinel évalué sur surface/prix minimum",
          formatter: (value) => `${this.percentage(value)}`,
        },
        {
          key: "yieldPinelMoy",
          label: "Rendement locatif Pinel évalué sur surface/prix moyen",
          formatter: (value) => `${this.percentage(value)}`,
        },
        {
          key: "yieldPinelMax",
          label: "Rendement locatif Pinel évalué sur surface/prix maximum",
          formatter: (value) => `${this.percentage(value)}`,
        },
      ],
    };
  },
  computed: {
    items() {
      const series = [];
      const typos = ["t1", "t2", "t3", "t4", "t5"];

      typos.forEach((type) => {
        if (
          this.program[type].living_area.min &&
          this.program[type].full_vat_price.price_parking_excepted.min
        ) {
          series.push({
            label: type.toUpperCase(),
            rentPinelMeter: this.ventesByPeriode.pinel.rent,
            rentPinelMin:
              this.ventesByPeriode.pinel.rent *
              this.program[type].living_area.min *
              12,
            rentPinelMoy:
              this.ventesByPeriode.pinel.rent *
              this.program[type].living_area.avg *
              12,
            rentPinelMax:
              this.ventesByPeriode.pinel.rent *
              this.program[type].living_area.max *
              12,
            yieldPinelMin:
              (this.ventesByPeriode.pinel.rent *
                this.program[type].living_area.min *
                12) /
              this.program[type].full_vat_price.price_parking_excepted.min,
            yieldPinelMoy:
              (this.ventesByPeriode.pinel.rent *
                this.program[type].living_area.avg *
                12) /
              this.program[type].full_vat_price.price_parking_excepted.avg,
            yieldPinelMax:
              (this.ventesByPeriode.pinel.rent *
                this.program[type].living_area.max *
                12) /
              this.program[type].full_vat_price.price_parking_excepted.max,
          });
        }
      });

      return series;
    },
  },
  methods: {
    currency,
    percentage,
  },
};
</script>
