<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Volumes</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre l’évolution du stock de logement en vente (total des logements en vente) sur les 4 dernières années avec le détails des entrées en stock (nouveaux logements commercialisés) et des sorties (logements vendus) sur le secteur du programme ou adresse sélectionné."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <sales-volume-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      </b-col>
      <b-col xl="12">
        <sales-volume-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.salesVolume.enable"
          :commentField="reportData.comments.salesVolume.value"
          v-on:update:comment="reportData.comments.salesVolume.value = $event"
          v-on:update:enable="reportData.comments.salesVolume.enable = $event"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import SalesVolumeArray from "./trend-elements/SalesVolumeArray";
import SalesVolumeChart from "./trend-elements/SalesVolumeChart";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    SalesVolumeArray,
    SalesVolumeChart,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
