<template>
  <div>
    <app-echart-doughnut :series="series" style="width: 100%" />

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee
        {{
          this.demographyByPeriode.data[
            this.demographyByPeriode.data.length - 3
          ].year
        }}</small
      >
    </div>
  </div>
</template>

<script>
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

export default {
  components: {
    AppEchartDoughnut,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const data =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 2]
        .household.last_year_rotation;

    return {
      series: [
        {
          name: "Lieu de résidence un an auparavant",
          type: "pie",
          radius: ["35%", "55%"],
          avoidLabelOverlap: true,
          tooltip: {
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          label: {
            show: true,
            formatter: "{name|{b}}\n{percent|{d}%}",
            fontSize: 15,
            rich: {
              percent: {
                fontSize: 14,
                color: "#999",
              },
            },
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 15,
            maxSurfaceAngle: 50,
          },
          data: [
            {
              value: data.same_lodgment,
              name: "Dans le \nmême logement",
            },
            {
              value: data.change_lodgment_same_city,
              name: "Dans un autre \nlogement de la \nmême commune",
            },
            {
              value:
                data.change_city_same_agglomeration +
                data.change_city_same_department +
                data.change_departement_same_region +
                data.change_region_mainland +
                data.change_no_mainland,
              name: "Dans une \nautre commune",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
