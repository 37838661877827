<template>
  <div>
    <app-echart-bar :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { percentage, crossProduct } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = ["Typologie"];

    const roomDivision =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
        .lodgment.division.main_lodgment_rooms_division;

    const total =
      roomDivision.t1 +
      roomDivision.t2 +
      roomDivision.t3 +
      roomDivision.t4 +
      roomDivision.t5;

    return {
      total,
      option: {
        xAxis: [
          {
            type: "category",
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
          top: "50px",
        },
        formatter: (params) => {
          return `
                ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value
          } (${crossProduct(params[0].value, total)}%)<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
            params[1].value
          } (${crossProduct(params[1].value, total)}%)<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[2].color ? params[2].color : null
                }"></span>${params[2].seriesName}: ${
            params[2].value
          } (${crossProduct(params[2].value, total)}%)<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[3].color ? params[3].color : null
                }"></span>${params[3].seriesName}: ${
            params[3].value
          } (${crossProduct(params[3].value, total)}%)<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[4].color ? params[4].color : null
                }"></span>${params[4].seriesName}: ${
            params[4].value
          } (${crossProduct(params[4].value, total)}%)`;
        },
        series: [
          {
            name: "T1",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].lodgment.division.main_lodgment_rooms_division.t1,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "T2",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].lodgment.division.main_lodgment_rooms_division.t2,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "T3",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].lodgment.division.main_lodgment_rooms_division.t3,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "T4",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].lodgment.division.main_lodgment_rooms_division.t4,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "T5",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].lodgment.division.main_lodgment_rooms_division.t5,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
        ],
      },
    };
  },
  methods: {
    crossProduct,
    percentage,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 17rem;
}
</style>
