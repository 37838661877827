<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell(label)="data">
        <span
          :style="{
            display: 'inline-block',
            'margin-right': '5px',
            'border-radius': '10px',
            width: '10px',
            height: '10px',
            'background-color': `${data.value.color}`,
          }"
        ></span
        >{{ data.value.title }}
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { currency, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    prixm2ByType: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          label: "Catégorie",
        },
        {
          key: "T1",
          label: "T1",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "T2",
          label: "T2",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "T3",
          label: "T3",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "T4",
          label: "T4",
          formatter: (value) => `${this.currency(value)}`,
        },
        {
          key: "T5",
          label: "T5+",
          formatter: (value) => `${this.currency(value)}`,
        },
      ],
    };
  },
  computed: {
    items() {
      const data = this.prixm2ByType.data[this.prixm2ByType.data.length - 1];

      const series = [
        {
          label: {
            title:
              "Prix/m² moyen logement neuf (avec parking) à proximité au " +
              formatPeriodQuarter(data.period),
            color: "#94D1BE",
          },
          T1: data.typology.t1.price_meter_new_lodgment_with_parking,
          T2: data.typology.t2.price_meter_new_lodgment_with_parking,
          T3: data.typology.t3.price_meter_new_lodgment_with_parking,
          T4: data.typology.t4.price_meter_new_lodgment_with_parking,
          T5: data.typology.t5.price_meter_new_lodgment_with_parking,
        },
        {
          label: {
            title:
              "Prix/m² moyen logement neuf (sans parking) à proximité au " +
              formatPeriodQuarter(data.period),
            color: "#c8ad7f",
          },
          T1: data.typology.t1.price_meter_new_lodgment_without_parking,
          T2: data.typology.t2.price_meter_new_lodgment_without_parking,
          T3: data.typology.t3.price_meter_new_lodgment_without_parking,
          T4: data.typology.t4.price_meter_new_lodgment_without_parking,
          T5: data.typology.t5.price_meter_new_lodgment_without_parking,
        },
      ];

      for (let index = 1; index <= this.prixm2ByType.data.length; index++) {
        const data =
          this.prixm2ByType.data[this.prixm2ByType.data.length - index];
        if (
          data.typology.t1.price_meter_old_lodgment === 0 &&
          data.typology.t2.price_meter_old_lodgment === 0 &&
          data.typology.t3.price_meter_old_lodgment === 0 &&
          data.typology.t4.price_meter_old_lodgment === 0 &&
          data.typology.t5.price_meter_old_lodgment === 0
        ) {
          continue;
        }
        series.push({
          label: {
            title:
              "Prix/m² moyen logement ancien à proximité au " +
              formatPeriodQuarter(data.period),
            color: "#00a1b5",
          },
          T1: data.typology.t1.price_meter_old_lodgment,
          T2: data.typology.t2.price_meter_old_lodgment,
          T3: data.typology.t3.price_meter_old_lodgment,
          T4: data.typology.t4.price_meter_old_lodgment,
          T5: data.typology.t5.price_meter_old_lodgment,
        });
        break;
      }

      if (this.program) {
        series.push({
          label: {
            title: "Prix/m² moyen du programme sélectionné (avec parking)",
            color: "#BE94D1",
          },
          T1: this.program.t1.full_vat_price.price_parking_included.avg
            ? this.program.t1.full_vat_price.price_parking_included.avg /
              this.program.t1.living_area.avg
            : null,
          T2: this.program.t2.full_vat_price.price_parking_included.avg
            ? this.program.t2.full_vat_price.price_parking_included.avg /
              this.program.t2.living_area.avg
            : null,
          T3: this.program.t3.full_vat_price.price_parking_included.avg
            ? this.program.t3.full_vat_price.price_parking_included.avg /
              this.program.t3.living_area.avg
            : null,
          T4: this.program.t4.full_vat_price.price_parking_included.avg
            ? this.program.t4.full_vat_price.price_parking_included.avg /
              this.program.t4.living_area.avg
            : null,
          T5: this.program.t5.full_vat_price.price_parking_included.avg
            ? this.program.t5.full_vat_price.price_parking_included.avg /
              this.program.t5.living_area.avg
            : null,
        });
        series.push({
          label: {
            title: "Prix/m² moyen du programme sélectionné (sans parking)",
            color: "#FF934F",
          },
          T1: this.program.t1.full_vat_price.price_parking_excepted.avg
            ? this.program.t1.full_vat_price.price_parking_excepted.avg /
              this.program.t1.living_area.avg
            : null,
          T2: this.program.t2.full_vat_price.price_parking_excepted.avg
            ? this.program.t2.full_vat_price.price_parking_excepted.avg /
              this.program.t2.living_area.avg
            : null,
          T3: this.program.t3.full_vat_price.price_parking_excepted.avg
            ? this.program.t3.full_vat_price.price_parking_excepted.avg /
              this.program.t3.living_area.avg
            : null,
          T4: this.program.t4.full_vat_price.price_parking_excepted.avg
            ? this.program.t4.full_vat_price.price_parking_excepted.avg /
              this.program.t4.living_area.avg
            : null,
          T5: this.program.t5.full_vat_price.price_parking_excepted.avg
            ? this.program.t5.full_vat_price.price_parking_excepted.avg /
              this.program.t5.living_area.avg
            : null,
        });
      }

      return series;
    },
  },
  methods: {
    currency,
  },
};
</script>
