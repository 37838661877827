<template>
  <div class="mt-3">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell()="data">
        <div v-if="data.value.volume >= 0">
          {{ bigNumberFormatter(data.value.volume) }}
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate > 0"
            pill
            variant="light-success"
            class="ml-1"
          >
            +{{ data.value.rate }}%
          </b-badge>
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate < 0"
            pill
            variant="light-secondary"
            class="ml-1"
          >
            {{ data.value.rate }}%
          </b-badge>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee</small
      >
    </div>
  </div>
</template>

<script>
import { BTable, BBadge } from "bootstrap-vue";
import { bigNumberFormatter, evalTrend } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bigNumberFormatter,
    evalTrend,
  },
  data() {
    const items = [];

    var lastElem = null;
    this.demographyByPeriode.data.forEach((element) => {
      if (lastElem != null) {
        items.push({
          year: element.year,
          population: {
            volume: element.population.population,
            rate: evalTrend(
              element.population.population,
              lastElem.population.population
            ),
          },
        });
      } else {
        items.push({
          year: element.year,
          population: {
            volume: element.population.population,
            rate: 0,
          },
        });
      }
      lastElem = element;
    });

    return {
      fields: [
        {
          key: "year",
          label: "Année",
        },
        {
          key: "population",
          label: "Population",
        },
      ],
      items,
    };
  },
};
</script>
