<template>
  <app-echart-bar :option-data="option" class="trend-chart" />
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { bigNumberFormatter } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const data =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
        .lodgment.division.main_lodgment_building_time_division;

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: [
              "Avant 1919",
              "1919 à 1945",
              "1946 à 1970",
              "1971 à 1990",
              "1991 à 2005",
              "2006 à 2015",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
          top: "50px",
        },
        formatter: (params) => {
          return `
                ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${bigNumberFormatter(
            params[0].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${bigNumberFormatter(
            params[1].value
          )}`;
        },
        series: [
          {
            name: "Appartement",
            type: "bar",
            stack: "advertising",
            data: [
              data.before_1919.appartment,
              data.from_1919_to_1945.appartment,
              data.from_1946_to_1970.appartment,
              data.from_1971_to_1990.appartment,
              data.from_1991_to_2005.appartment,
              data.from_2006_to_2015.appartment,
            ],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Maison",
            type: "bar",
            stack: "advertising",
            data: [
              data.before_1919.house,
              data.from_1919_to_1945.house,
              data.from_1946_to_1970.house,
              data.from_1971_to_1990.house,
              data.from_1991_to_2005.house,
              data.from_2006_to_2015.house,
            ],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
