var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"small":"","responsive":""},scopedSlots:_vm._u([{key:"cell(label)",fn:function(data){return [_c('span',{style:({
          display: 'inline-block',
          'margin-right': '5px',
          'border-radius': '10px',
          width: '10px',
          height: '10px',
          'background-color': ("" + (data.value.color)),
        })}),_vm._v(_vm._s(data.value.title)+" ")]}}])}),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"},[_c('small'),_c('small',{staticClass:"timeline-item-time text-nowrap text-muted ml-1"},[_vm._v("Source: Adéquation")])])}]

export { render, staticRenderFns }