<template>
  <div class="mt-2">
    <b-table-simple hover caption-top responsive class="rounded-bottom mb-2">
      <b-thead head-variant="light">
        <b-tr>
          <b-th colspan="2"> Catégorie </b-th>
          <b-th colspan="1"> Volume </b-th>
          <b-th colspan="1"> Taux </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th rowspan="3"> Ménages avec familles </b-th>
          <b-th> Couples avec enfant(s) </b-th>
          <b-td>{{ this.data.family_division.with_child }}</b-td>
          <b-td
            >{{
              crossProduct(this.data.family_division.with_child, this.total)
            }}%</b-td
          >
        </b-tr>
        <b-tr>
          <b-th> Couples sans enfant </b-th>
          <b-td>{{ this.data.family_division.without_child }}</b-td>
          <b-td
            >{{
              crossProduct(this.data.family_division.without_child, this.total)
            }}%</b-td
          >
        </b-tr>
        <b-tr>
          <b-th> Familles monoparentales </b-th>
          <b-td>{{ this.data.family_division.single_parent }}</b-td>
          <b-td
            >{{
              crossProduct(this.data.family_division.single_parent, this.total)
            }}%</b-td
          >
        </b-tr>
        <b-tr>
          <b-th rowspan="2"> Ménages d'une personne </b-th>
          <b-th> Femmes seules </b-th>
          <b-td> {{ this.data.single_person_division.woman }} </b-td>
          <b-td
            >{{
              crossProduct(this.data.single_person_division.woman, this.total)
            }}%</b-td
          >
        </b-tr>
        <b-tr>
          <b-th> Hommes seuls </b-th>
          <b-td>{{ this.data.single_person_division.man }}</b-td>
          <b-td
            >{{
              crossProduct(this.data.single_person_division.man, this.total)
            }}%</b-td
          >
        </b-tr>
        <b-tr>
          <b-th rowspan="1"> Autres ménages </b-th>
          <b-th> Autres ménages </b-th>
          <b-td> {{ this.data.family_division.other }} </b-td>
          <b-td
            >{{
              crossProduct(this.data.family_division.other, this.total)
            }}%</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee
        {{
          this.demographyByPeriode.data[
            this.demographyByPeriode.data.length - 1
          ].year
        }}</small
      >
    </div>
  </div>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody } from "bootstrap-vue";
import { crossProduct } from "@core/utils/filter";

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const year =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
        .year;
    const data =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
        .lodgment.division.household.division;
    const total =
      data.family_division.with_child +
      data.family_division.without_child +
      data.family_division.single_parent +
      data.single_person_division.woman +
      data.single_person_division.man +
      data.family_division.other;

    return {
      year,
      data,
      total,
    };
  },
  methods: {
    crossProduct,
  },
};
</script>
