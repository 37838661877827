<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Rendement locatif</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre l’évolution du rendement locatif moyen dans la commune du programme ou adresse sélectionné."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <rental-yield-trend-graph
          :rentTrend="reportData.rentTrend"
          :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <rental-yield-trend-array
          :rentTrend="reportData.rentTrend"
          :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.rentalYieldTrend.enable"
          :commentField="reportData.comments.rentalYieldTrend.value"
          v-on:update:comment="
            reportData.comments.rentalYieldTrend.value = $event
          "
          v-on:update:enable="
            reportData.comments.rentalYieldTrend.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import RentalYieldTrendArray from "./trend-elements/RentalYieldTrendArray";
import RentalYieldTrendGraph from "./trend-elements/RentalYieldTrendGraph";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    RentalYieldTrendArray,
    RentalYieldTrendGraph,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
