<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Emploi & Chômage</h4>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre l’évolution du nombre de total de travailleurs et du taux de chômage (INSEE) pour les 4 dernières années jusqu’au dernier recensement sur le secteur du programme ou adresse sélectionné."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7">
        <employments-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="5">
        <employments-array
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.employments.enable"
          :commentField="reportData.comments.employments.value"
          v-on:update:comment="reportData.comments.employments.value = $event"
          v-on:update:enable="reportData.comments.employments.enable = $event"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import EmploymentsChart from "./employments-elements/EmploymentsChart";
import EmploymentsArray from "./employments-elements/EmploymentsArray";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    EmploymentsChart,
    EmploymentsArray,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
