<template>
  <div>
    <app-echart-bar :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { percentage } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = ["Catégories socioprofessionelles"];

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
          top: "90px",
        },
        formatter: (params) => {
          return `
                ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${percentage(
            params[0].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${percentage(
            params[1].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[2].color ? params[2].color : null
                }"></span>${params[2].seriesName}: ${percentage(
            params[2].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[3].color ? params[3].color : null
                }"></span>${params[3].seriesName}: ${percentage(
            params[3].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[4].color ? params[4].color : null
                }"></span>${params[4].seriesName}: ${percentage(
            params[4].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[5].color ? params[5].color : null
                }"></span>${params[5].seriesName}: ${percentage(
            params[5].value
          )}<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[6].color ? params[6].color : null
                }"></span>${params[6].seriesName}: ${percentage(
            params[6].value
          )}`;
        },
        series: [
          {
            name: "Agriculteurs exploitants",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.farmer_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Artisans/commerçants/entrepreneurs",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.entrepreneur_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Cadres et professions IS",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.senior_executive_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Professions intermédiaires",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.intermediaite_profession_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Salariés",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.employee_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Ouvriers",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.worker_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Retraités",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.retired_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Inactifs",
            type: "bar",
            data: [
              this.demographyByPeriode.data[
                this.demographyByPeriode.data.length - 1
              ].employment.psc.without_activity_rate,
            ],
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
        ],
      },
    };
  },
  methods: {
    percentage,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
