<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Autres programmes</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet affichage permet de visualiser une sélection de programmes à proximité."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8">
        <ecosystem-programs-array :reportData="reportData" />
      </b-col>
      <b-col xl="4">
        <ecosystem-programs-map :reportData="reportData" />
      </b-col>
      <b-col xl="12" class="mt-1">
        <comment-form-text-area
          :enable="reportData.comments.programsEcosystem.enable"
          :commentField="reportData.comments.programsEcosystem.value"
          v-on:update:comment="
            reportData.comments.programsEcosystem.value = $event
          "
          v-on:update:enable="
            reportData.comments.programsEcosystem.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VBTooltip, BCard, BRow, BCol, BAvatar } from "bootstrap-vue";
import EcosystemProgramsArray from "./ecosystem-elements/EcosystemProgramsArray";
import EcosystemProgramsMap from "./ecosystem-elements/EcosystemProgramsMap";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    EcosystemProgramsArray,
    EcosystemProgramsMap,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
