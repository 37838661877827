<template>
  <section class="mx-2 mb-2">
    <div v-if="busStops.length > 0" class="mb-1">
      <h5>Bus</h5>
      <div v-for="busStop in busStops" :key="busStop.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <i class="fas fa-bus fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ busStop.name }}</h6>
            <small>à {{ busStop.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="tramStops.length > 0" class="mb-1">
      <h5>Tram</h5>
      <div v-for="tramStop in tramStops" :key="tramStop.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <i class="fas fa-train fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ tramStop.name }}</h6>
            <small>à {{ tramStop.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="subwayStops.length > 0" class="mb-1">
      <h5>Métro</h5>
      <div v-for="subwayStop in subwayStops" :key="subwayStop.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-danger">
              <i class="fas fa-subway fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ subwayStop.name }}</h6>
            <small>à {{ subwayStop.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </section>
</template>

<script>
import { BMediaBody, BMedia, BMediaAside, BAvatar } from "bootstrap-vue";

// leaflet
import L from "leaflet";
import "@fortawesome/fontawesome-free/js/all.js";

export default {
  components: {
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "reportData.busStops"(val) {
      this.busStops = this.formatOverpassResp(val, 2);
    },
    "reportData.tramStops"(val) {
      this.tramStops = this.formatOverpassResp(val, 2);
    },
    "reportData.subwayStops"(val) {
      this.subwayStops = this.formatOverpassResp(val, 2);
    },
  },
  data() {
    return {
      busStops: [],
      tramStops: [],
      subwayStops: [],
    };
  },
  methods: {
    formatOverpassResp(array, limit) {
      return (
        Array.from(
          // distinct by name (remove double)
          new Set(array.map((s) => s.tags["name"]))
        )
          .map((name) => {
            // rebuild the object back
            const found = array.find((s) => s.tags["name"] === name);
            return {
              id: found.id,
              name: name,
              latlng: L.latLng(found.lat, found.lon),
              distance: L.latLng(found.lat, found.lon)
                .distanceTo(
                  L.latLng(
                    this.reportData.center.latitude,
                    this.reportData.center.longitude
                  )
                )
                .toFixed(0),
            };
          })
          // sort by distance asc
          .sort(function (a, b) {
            return a.distance - b.distance;
          })
          // keep only stop with distance < 1.5km
          .filter((elem) => elem.distance < 1500)
          // keep only 2 first result
          .slice(0, limit)
      );
    },
  },
};
</script>
