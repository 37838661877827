<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const salesSpeedData = [];

    this.ventesByPeriode.data.forEach((element) => {
      xAxisData.push(formatPeriodQuarter(element.period));
      salesSpeedData.push(parseFloat(element.rhythm.average_rhythm));
    });

    return {
      option: {
        xAxisData,
        series: [
          {
            name: "Durée moyenne de commercialisation",
            type: "line",
            showSymbol: true,
            data: salesSpeedData,
          },
        ],
        formatter: (params) => {
          return `
                Année: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? params[0].value + " mois" : "-"
          }`;
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
