<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    rentTrend: {
      type: Object,
      required: true,
    },
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const rentData = [];
    const rentPinelData = [];

    var i = 1;
    this.rentTrend.data.forEach((element) => {
      xAxisData.push(element.year);
      element.rent_meter !== 0
        ? rentData.push(Number(element.rent_meter).toFixed(2))
        : rentData.push(null);
      this.rentTrend.data.length === i
        ? rentPinelData.push(this.ventesByPeriode.pinel.rent)
        : rentPinelData.push(null);
      i++;
    });

    return {
      option: {
        xAxisData,
        series: [
          {
            name: "Loyer/m² moyen",
            type: "line",
            showSymbol: true,
            lineStyle: {
              width: 3,
            },
            data: rentData,
          },
          {
            name: "Loyer/m² Pinel",
            type: "line",
            showSymbol: true,
            symbol: "arrow",
            symbolSize: 20,
            lineStyle: {
              width: 3,
            },
            data: rentPinelData,
          },
        ],
        formatter: (params) => {
          var tooltip = `
                Année: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? params[0].value + "€" : "-"
          }`;
          if (params.length >= 2) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
                params[1].value ? params[1].value + "€" : "-"
              }`;
          }
          return tooltip;
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
