<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Loyer m²</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre l’évolution du prix des loyers au m² dans le secteur du programme ou adresse sélectionné sur les 4 dernières années."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4">
        <rent-array
          :rentTrend="reportData.rentTrend"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="8">
        <rent-graph
          :rentTrend="reportData.rentTrend"
          :ventesByPeriode="reportData.ventesByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.rentTrend.enable"
          :commentField="reportData.comments.rentTrend.value"
          v-on:update:comment="reportData.comments.rentTrend.value = $event"
          v-on:update:enable="reportData.comments.rentTrend.enable = $event"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import RentArray from "./trend-elements/RentArray";
import RentGraph from "./trend-elements/RentGraph";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    RentArray,
    RentGraph,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
