<template>
  <l-map
    ref="map"
    class="map-size"
    :zoom="zoom"
    :center="center"
    :options="{
      dragging: false,
      scrollWheelZoom: false,
      touchZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      keyboard: false,
    }"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="marker" :icon="iconFlag"></l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import L from "leaflet";
import "leaflet-overpass-layer";

// Custom marker for leaflet map
import "@fortawesome/fontawesome-free/js/all.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

import { ref } from "@vue/composition-api";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoom: 17,
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution:
        '&copy; <a href="http://osm.org/copyright"></a><a href="http://www.overpass-api.de/">Overpass API</a>',
      iconFlag: L.AwesomeMarkers.icon({
        icon: "flag",
        markerColor: "green",
        iconColor: "white",
        prefix: "fa",
      }),
      iconDoctor: L.AwesomeMarkers.icon({
        icon: "user-md",
        markerColor: "darkgreen",
        iconColor: "white",
        prefix: "fa",
      }),
      iconClinic: L.AwesomeMarkers.icon({
        icon: "clinic-medical",
        markerColor: "orange",
        iconColor: "white",
        prefix: "fa",
      }),
      iconHospital: L.AwesomeMarkers.icon({
        icon: "h-square",
        markerColor: "red",
        iconColor: "white",
        prefix: "fa",
      }),
      iconPharmacy: L.AwesomeMarkers.icon({
        icon: "prescription-bottle-alt",
        markerColor: "darkblue",
        iconColor: "white",
        prefix: "fa",
      }),
      iconNursing: L.AwesomeMarkers.icon({
        icon: "hand-holding-heart",
        markerColor: "darkpurple",
        iconColor: "white",
        prefix: "fa",
      }),
    };
  },
  setup(props) {
    let marker = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );
    let center = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );

    return {
      marker,
      center,
    };
  },
  mounted() {
    var map = this.$refs.map.mapObject;

    var overpassOption = {
      minZoom: 17,
      timeout: 30 * 1000, // Milliseconds
      retryOnTimeout: true,
      minZoomIndicatorEnabled: false,
      endPoint: this.$config.VUE_APP_OVERPASS_URL,
      onSuccess(data) {
        for (let i = 0; i < data.elements.length; i++) {
          const e = data.elements[i];

          if (e.tags) {
            let layer;

            if (e.id in this._ids) {
              continue;
            }

            this._ids[e.id] = true;

            if (e.type === "way") {
              let found = data.elements.find((elem) => elem.id === e.nodes[0]);
              found.tags = { name: e.tags["name"] };
              this.options.saveRespVar
                ? this.options.reportData[this.options.saveRespVar].push(found)
                : null;
              let pos = L.latLng(found.lat, found.lon);
              layer = this.options.markerIcon
                ? L.marker(pos, { icon: this.options.markerIcon })
                : L.marker(pos);

              const span = document.createElement("h5");
              const div = document.createElement("div");

              span.appendChild(document.createTextNode(e.tags["name"]));

              div.appendChild(span);

              const popup = L.popup({ autoPan: false }).setContent(div);
              layer.bindPopup(popup);
            } else if (e.type === "node") {
              this.options.saveRespVar
                ? this.options.reportData[this.options.saveRespVar].push(e)
                : null;

              let pos = L.latLng(e.lat, e.lon);
              layer = this.options.markerIcon
                ? L.marker(pos, { icon: this.options.markerIcon })
                : L.marker(pos);

              const span = document.createElement("h5");
              const div = document.createElement("div");

              span.appendChild(document.createTextNode(e.tags["name"]));

              div.appendChild(span);

              const popup = L.popup({ autoPan: false }).setContent(div);
              layer.bindPopup(popup);
            } else {
              continue;
            }

            this._markers.addLayer(layer);
          }
        }
      },
    };

    var doctors = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "doctors",
        markerIcon: this.iconDoctor,
        query:
          "(node[amenity=doctors]({{bbox}});node[amenity=dentist]({{bbox}}););out 300;",
      })
    );
    map.addLayer(doctors);

    var clinics = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "clinics",
        markerIcon: this.iconClinic,
        query: "(node[amenity=clinic]({{bbox}}););out 300;",
      })
    );
    map.addLayer(clinics);

    var hospitals = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "hospitals",
        markerIcon: this.iconHospital,
        query:
          "(node[amenity=hospital]({{bbox}});way[amenity=hospital]({{bbox}});relation[amenity=hospital]({{bbox}}););out body;>;out skel qt;",
      })
    );
    map.addLayer(hospitals);

    var pharmacies = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "pharmacies",
        markerIcon: this.iconPharmacy,
        query: "(node[amenity=pharmacy]({{bbox}}););out 300;",
      })
    );
    map.addLayer(pharmacies);

    var nursingHomes = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "nursingHomes",
        markerIcon: this.iconNursing,
        query:
          "(node[amenity=social_facility][social_facility=nursing_home]({{bbox}}););out 300;",
      })
    );
    map.addLayer(nursingHomes);

    setTimeout(() => {
      map.setView(this.center, 15);
    }, 600);
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.map-size {
  height: 30rem !important;
}
</style>
