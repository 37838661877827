var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-map',{ref:"map",staticClass:"map-size",attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":{
    dragging: false,
    scrollWheelZoom: false,
    touchZoom: false,
    doubleClickZoom: false,
    boxZoom: false,
    keyboard: false,
  }}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-marker',{attrs:{"lat-lng":_vm.marker,"icon":_vm.iconFlag}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }