<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell()="data">
        <div v-if="data.value.volume >= 0">
          {{ bigNumberFormatter(data.value.volume) }}
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate > 0"
            pill
            variant="light-success"
            class="ml-1"
          >
            +{{ data.value.rate }}%
          </b-badge>
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate < 0"
            pill
            variant="light-secondary"
            class="ml-1"
          >
            {{ data.value.rate }}%
          </b-badge>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable, BBadge } from "bootstrap-vue";
import {
  bigNumberFormatter,
  evalTrend,
  formatPeriodQuarter,
} from "@core/utils/filter";

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bigNumberFormatter,
    evalTrend,
  },
  data() {
    const items = [];

    var lastElem = null;
    this.ventesByPeriode.data.forEach((element) => {
      if (lastElem != null) {
        items.push({
          period: formatPeriodQuarter(element.period),
          commercialized: {
            volume: element.volumes.commercialized,
            rate: evalTrend(
              element.volumes.commercialized,
              lastElem.volumes.commercialized
            ),
          },
          sold: {
            volume: element.volumes.sold,
            rate: evalTrend(element.volumes.sold, lastElem.volumes.sold),
          },
          available_offer: {
            volume: element.volumes.available_offer,
            rate: evalTrend(
              element.volumes.available_offer,
              lastElem.volumes.available_offer
            ),
          },
        });
      } else {
        items.push({
          period: formatPeriodQuarter(element.period),
          commercialized: {
            volume: element.volumes.commercialized,
            rate: 0,
          },
          sold: {
            volume: element.volumes.sold,
            rate: 0,
          },
          available_offer: {
            volume: element.volumes.available_offer,
            rate: 0,
          },
        });
      }
      lastElem = element;
    });

    return {
      fields: [
        {
          key: "period",
          label: "Année",
        },
        {
          key: "commercialized",
          label: "Nouveaux logements commercialisés",
        },
        {
          key: "sold",
          label: "Logements vendus",
        },
        {
          key: "available_offer",
          label: "Total logements en vente",
        },
      ],
      items,
    };
  },
};
</script>
