<template>
  <div>
    <app-echart-doughnut :series="series" style="width: 100%" />
  </div>
</template>

<script>
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

export default {
  components: {
    AppEchartDoughnut,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: "Logements",
          type: "pie",
          radius: "55%",
          center: ["50%", "50%"],
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: function () {
            return Math.random() * 200;
          },
          avoidLabelOverlap: true,
          tooltip: {
            formatter: "{a} <br/>{b} : {d}%",
          },
          label: {
            show: true,
            formatter: "{name|{b}}\n{percent|{d}%}",
            minMargin: 5,
            fontSize: 14,
            lineHeight: 15,
            rich: {
              percent: {
                fontSize: 14,
                color: "#999",
              },
            },
          },
          labelLine: {
            show: true,
            length: 10,
            length2: 5,
            maxSurfaceAngle: 80,
          },
          data: [
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].lodgment.occupation.owner_rate,
              name: "Propriétaires",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].lodgment.occupation.tenant_rate,
              name: "Locataires",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].lodgment.occupation.social_tenant_rate,
              name: "Locataires HLM",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].lodgment.occupation.free_tenant_rate,
              name: "Logés gratuitement",
            },
          ].sort(function (a, b) {
            return a.value - b.value;
          }),
        },
      ],
    };
  },
};
</script>
