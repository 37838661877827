<template>
  <l-map
    ref="map"
    class="map-size"
    :zoom="zoom"
    :center="center"
    :options="{
      dragging: false,
      scrollWheelZoom: false,
      touchZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      keyboard: false,
    }"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="marker" :icon="iconFlag"></l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import L from "leaflet";
import "leaflet-overpass-layer";

// Custom marker for leaflet map
import "@fortawesome/fontawesome-free/js/all.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

import { ref } from "@vue/composition-api";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      zoom: 17,
      url: this.$config.VUE_APP_MAP_TILE_URL,
      attribution:
        '&copy; <a href="http://osm.org/copyright"></a><a href="http://www.overpass-api.de/">Overpass API</a>',
      iconFlag: L.AwesomeMarkers.icon({
        icon: "flag",
        markerColor: "green",
        iconColor: "white",
        prefix: "fa",
      }),
      iconBus: L.AwesomeMarkers.icon({
        icon: "bus",
        markerColor: "blue",
        iconColor: "white",
        prefix: "fa",
      }),
      iconTram: L.AwesomeMarkers.icon({
        icon: "train",
        markerColor: "darkpurple",
        iconColor: "white",
        prefix: "fa",
      }),
      iconSubway: L.AwesomeMarkers.icon({
        icon: "subway",
        markerColor: "red",
        iconColor: "white",
        prefix: "fa",
      }),
    };
  },
  setup(props) {
    let marker = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );
    let center = ref(
      L.latLng(
        props.reportData.center.latitude,
        props.reportData.center.longitude
      )
    );

    return {
      marker,
      center,
    };
  },
  mounted() {
    var map = this.$refs.map.mapObject;

    var overpassOption = {
      minZoom: 17,
      timeout: 8 * 1000, // Milliseconds
      retryOnTimeout: true,
      minZoomIndicatorEnabled: false,
      endPoint: this.$config.VUE_APP_OVERPASS_URL,
      onSuccess(data) {
        this.options.saveRespVar
          ? (this.options.reportData[this.options.saveRespVar] = data.elements)
          : null;
        for (let i = 0; i < data.elements.length; i++) {
          let layer;
          const e = data.elements[i];

          if (e.id in this._ids) {
            continue;
          }

          this._ids[e.id] = true;

          if (this.options.markerStyle === "way" && e.type === "way") {
            let line = [];
            e.nodes.forEach((element) => {
              let found = data.elements.find((e) => e.id === element);
              let coord = new L.LatLng(found.lat, found.lon);
              line.push(coord);
            });
            layer = new L.Polyline(line, this.options.lineOption);
          } else if (this.options.markerStyle === "node" && e.type === "node") {
            let pos = L.latLng(e.lat, e.lon);
            layer = this.options.markerIcon
              ? L.marker(pos, { icon: this.options.markerIcon })
              : L.marker(pos);

            const span = document.createElement("h5");
            const div = document.createElement("div");

            span.appendChild(document.createTextNode(e.tags["name"]));

            div.appendChild(span);

            const popup = L.popup({ autoPan: false }).setContent(div);
            layer.bindPopup(popup);
          } else {
            continue;
          }

          this._markers.addLayer(layer);
        }
      },
    };

    var busStops = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "busStops",
        markerStyle: "node",
        markerIcon: this.iconBus,
        query: "(node[highway=stop][highway=bus_stop]({{bbox}}););out 300;",
      })
    );
    map.addLayer(busStops);

    var tramStops = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "tramStops",
        markerStyle: "node",
        markerIcon: this.iconTram,
        query: "(node[railway=tram_stop]({{bbox}}););out 300;",
      })
    );
    map.addLayer(tramStops);

    var tramLines = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        markerStyle: "way",
        lineOption: {
          color: "blue",
          weight: 3,
          opacity: 0.5,
          smoothFactor: 1,
        },
        query: "(way[railway=tram]({{bbox}}););out body;>;out skel qt;",
      })
    );
    map.addLayer(tramLines);

    var subwayStops = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        reportData: this.reportData,
        saveRespVar: "subwayStops",
        markerStyle: "node",
        markerIcon: this.iconSubway,
        query:
          "(node[public_transport=station][subway=yes]({{bbox}}););out 300;",
      })
    );
    map.addLayer(subwayStops);

    var subwayLines = new L.OverPassLayer(
      Object.assign({}, overpassOption, {
        markerStyle: "way",
        lineOption: {
          color: "red",
          weight: 3,
          opacity: 0.5,
          smoothFactor: 1,
        },
        query: "(way[railway=subway]({{bbox}}););out body;>;out skel qt;",
      })
    );
    map.addLayer(subwayLines);

    setTimeout(() => {
      map.setView(this.center, 15);
    }, 600);
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";

.map-size {
  height: 30rem !important;
}
</style>
