<template>
  <b-card title="Éducation">
    <b-row>
      <b-col cols="4">
        <education-details :reportData="reportData" />
      </b-col>
      <b-col cols="8">
        <education-map :reportData="reportData" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import EducationMap from "./education-elements/EducationMap";
import EducationDetails from "./education-elements/EducationDetails";

export default {
  components: {
    BCard,
    BRow,
    BCol,

    // Components Vue
    EducationMap,
    EducationDetails,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
