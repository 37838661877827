<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Prix m² par période</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre l’évolution des prix de vente au m² sur le
            marché du neuf et de l’ancien dans le secteur du programme ou
            adresse sélectionné sur les 4 dernières années."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <!-- <b-col xl="12">
        <square-meters-price-analysis-trend
          :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
        />
      </b-col> -->
      <b-col xl="12">
        <square-meters-price-chart-trend
          :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
          :program="reportData.program"
        />
      </b-col>
      <b-col xl="12">
        <square-meters-price-array-trend
          :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
          :program="reportData.program"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.squareMetersTrend.enable"
          :commentField="reportData.comments.squareMetersTrend.value"
          v-on:update:comment="
            reportData.comments.squareMetersTrend.value = $event
          "
          v-on:update:enable="
            reportData.comments.squareMetersTrend.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VBTooltip, BCard, BRow, BCol, BAvatar } from "bootstrap-vue";
import SquareMetersPriceArrayTrend from "./trend-elements/SquareMetersPriceArrayTrend";
import SquareMetersPriceChartTrend from "./trend-elements/SquareMetersPriceChartTrend";
/* import SquareMetersPriceAnalysisTrend from "./trend-elements/SquareMetersPriceAnalysisTrend"; */
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    SquareMetersPriceArrayTrend,
    SquareMetersPriceChartTrend,
    /* SquareMetersPriceAnalysisTrend, */
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data: () => ({
    maxChar: 1024,
  }),
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
