<template>
  <section class="mx-2 mb-2">
    <div v-if="hospitals.length > 0" class="mb-1">
      <h5>Hopital</h5>
      <div v-for="hospital in hospitals" :key="hospital.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-danger">
              <i class="fas fa-h-square fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ hospital.name }}</h6>
            <small>à {{ hospital.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="doctors.length > 0" class="mb-1">
      <h5>Docteur</h5>
      <div v-for="doctor in doctors" :key="doctor.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-success">
              <i class="fas fa-user-md fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ doctor.name }}</h6>
            <small>à {{ doctor.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="pharmacies.length > 0" class="mb-1">
      <h5>Pharmacie</h5>
      <div v-for="pharmacy in pharmacies" :key="pharmacy.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-primary">
              <i class="fas fa-prescription-bottle-alt fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ pharmacy.name }}</h6>
            <small>à {{ pharmacy.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="clinics.length > 0" class="mb-1">
      <h5>Clinique</h5>
      <div v-for="clinic in clinics" :key="clinic.id" class="my-50">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-warning">
              <i class="fas fa-clinic-medical fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ clinic.name }}</h6>
            <small>à {{ clinic.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
    <div v-if="nursingHomes.length > 0" class="mb-1">
      <h5>Maison de repos</h5>
      <div
        v-for="nursingHome in nursingHomes"
        :key="nursingHome.id"
        class="my-50"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" variant="light-info">
              <i class="fas fa-hand-holding-heart fa-2x"></i>
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">{{ nursingHome.name }}</h6>
            <small>à {{ nursingHome.distance }}m</small>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </section>
</template>

<script>
import { BMediaBody, BMedia, BMediaAside, BAvatar } from "bootstrap-vue";

// leaflet
import L from "leaflet";
import "@fortawesome/fontawesome-free/js/all.js";

export default {
  components: {
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "reportData.doctors"(val) {
      this.doctors = this.formatOverpassResp(val, 2);
    },
    "reportData.pharmacies"(val) {
      this.pharmacies = this.formatOverpassResp(val, 1);
    },
    "reportData.clinics"(val) {
      this.clinics = this.formatOverpassResp(val, 1);
    },
    "reportData.nursingHomes"(val) {
      this.nursingHomes = this.formatOverpassResp(val, 1);
    },
    "reportData.hospitals"(val) {
      this.hospitals = this.formatOverpassResp(val, 1);
    },
  },
  data() {
    return {
      doctors: [],
      pharmacies: [],
      clinics: [],
      nursingHomes: [],
      hospitals: [],
    };
  },
  methods: {
    formatOverpassResp(array, limit) {
      return (
        Array.from(
          // distinct by name (remove double)
          new Set(array.map((s) => s.tags["name"]))
        )
          .map((name) => {
            // rebuild the object back
            const found = array.find((s) => s.tags["name"] === name);
            return {
              id: found.id,
              name: name,
              latlng: L.latLng(found.lat, found.lon),
              distance: L.latLng(found.lat, found.lon)
                .distanceTo(
                  L.latLng(
                    this.reportData.center.latitude,
                    this.reportData.center.longitude
                  )
                )
                .toFixed(0),
            };
          })
          // sort by distance asc
          .sort(function (a, b) {
            return a.distance - b.distance;
          })
          // keep only stop with distance < 1.5km
          .filter((elem) => elem.distance < 1500)
          // keep only 2 first result
          .slice(0, limit)
      );
    },
  },
};
</script>
