<template>
  <div v-if="reportData.demographyByPeriode.length > 0">
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="DollarSignIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Economie</h4>
          <span
            >Dans la section 'Economie', vous trouverez les indicateurs
            nécessaires pour comprendre la dynamique économique du
            secteur.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-17">
      <b-col md="12">
        <employment-division-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
    <b-row id="item-18">
      <b-col md="12">
        <employments-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import EmploymentDivisionCard from "./employment-division/EmploymentDivisionCard";
import EmploymentsCard from "./employments/EmploymentsCard";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    EmploymentDivisionCard,
    EmploymentsCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
