<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Période d'achèvement des résidences principales</h4>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre la répartition des résidences principales par période d’achèvement dans la commune du programme ou adresse sélectionné au dernier recensement INSEE."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7">
        <building-time-division-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="5">
        <building-time-division-array
          :demographyByPeriode="reportData.demographyByPeriode[0]"
          class="mt-3"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.buildingTimeDivision.enable"
          :commentField="reportData.comments.buildingTimeDivision.value"
          v-on:update:comment="
            reportData.comments.buildingTimeDivision.value = $event
          "
          v-on:update:enable="
            reportData.comments.buildingTimeDivision.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import BuildingTimeDivisionChart from "./building-time-division-elements/BuildingTimeDivisionChart";
import BuildingTimeDivisionArray from "./building-time-division-elements/BuildingTimeDivisionArray";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    BuildingTimeDivisionChart,
    BuildingTimeDivisionArray,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.type-chart {
  width: 100%;
  height: 21rem;
}
</style>
