<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const commercializedData = [];
    const soldData = [];

    this.ventesByPeriode.data.forEach((element) => {
      xAxisData.push(formatPeriodQuarter(element.period));
      element.volumes.commercialized !== 0
        ? commercializedData.push(element.volumes.commercialized)
        : commercializedData.push(null);
      element.volumes.sold !== 0
        ? soldData.push(element.volumes.sold)
        : soldData.push(null);
    });

    return {
      option: {
        xAxisData,
        series: [
          {
            name: "Nouveaux logements",
            type: "line",
            showSymbol: true,
            lineStyle: {
              width: 3,
            },
            data: commercializedData,
          },
          {
            name: "Ventes logements",
            type: "line",
            showSymbol: true,
            lineStyle: {
              width: 3,
            },
            data: soldData,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
