<template>
  <div>
    <app-echart-bar :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const investorData = [];
    const residentData = [];

    this.ventesByPeriode.data.forEach((element) => {
      xAxisData.push(formatPeriodQuarter(element.period));
      investorData.push(element.investments.total_investor);
      residentData.push(element.investments.total_resident);
    });

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "Total investissement",
            type: "bar",
            data: investorData,
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Total résidentiel",
            type: "bar",
            data: residentData,
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
