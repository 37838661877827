<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive> </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee
        {{
          this.demographyByPeriode.data[
            this.demographyByPeriode.data.length - 1
          ].year
        }}</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { percentage } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          label: "Catégorie socioprofessionnel",
        },
        {
          key: "value",
          label: "Taux",
          formatter: (value) => {
            return `${this.percentage(value)}`;
          },
        },
      ],
    };
  },
  computed: {
    items() {
      return [
        {
          label: "Agriculteurs exploitants",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.farmer_rate,
        },
        {
          label: "Artisans, commerçants, chefs entreprise",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.entrepreneur_rate,
        },
        {
          label: "Cadres et professions intellectuelles supérieures",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.senior_executive_rate,
        },
        {
          label: "Professions intermédiaires",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.intermediaite_profession_rate,
        },
        {
          label: "Salariés",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.employee_rate,
        },
        {
          label: "Ouvriers",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.worker_rate,
        },
        {
          label: "Retraités",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.retired_rate,
        },
        {
          label: "Inactifs",
          value:
            this.demographyByPeriode.data[
              this.demographyByPeriode.data.length - 1
            ].employment.psc.without_activity_rate,
        },
      ];
    },
  },
  methods: {
    percentage,
  },
};
</script>
