<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Occupation des logements</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre la répartition des occupants en donnant la part des propriétaires, des locataires, des locataires HLM et des logés gratuitement sur le secteur du programme ou adresse sélectionné pour les 4 dernières années."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col xl="5">
        <settle-survey-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="5">
        <shelters-survey-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.settleSurvey.enable"
          :commentField="reportData.comments.settleSurvey.value"
          v-on:update:comment="reportData.comments.settleSurvey.value = $event"
          v-on:update:enable="reportData.comments.settleSurvey.enable = $event"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import SettleSurveyChart from "./settle-survey-elements/SettleSurveyChart";
import SheltersSurveyChart from "./settle-survey-elements/SheltersSurveyChart";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    SettleSurveyChart,
    SheltersSurveyChart,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>
