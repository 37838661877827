<template>
  <div v-if="reportData.demographyByPeriode.length > 0">
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="HomeIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Logements</h4>
          <span
            >Dans la section 'Logements', vous trouverez les indicateurs
            nécessaires pour comprendre la composition des logements du
            secteur.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-11">
      <b-col md="12">
        <logement-type-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-12">
      <b-col md="12">
        <main-residence-type-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-13">
      <b-col md="12">
        <building-time-division-card
          :reportData="reportData"
          v-on="$listeners"
        />
      </b-col>
    </b-row>

    <b-row id="item-14">
      <b-col md="12">
        <last-year-rotation-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-15">
      <b-col md="12">
        <move-in-age-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-16">
      <b-col md="12">
        <settle-survey-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import LogementTypeCard from "./logement-type/LogementTypeCard";
import MainResidenceTypeCard from "./main-residence-type/MainResidenceTypeCard";
import BuildingTimeDivisionCard from "./building-time-division/BuildingTimeDivisionCard";
import LastYearRotationCard from "./last-year-rotation/LastYearRotationCard";
import MoveInAgeCard from "./move-in-age/MoveInAgeCard";
import SettleSurveyCard from "./settle-survey/SettleSurveyCard";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    LogementTypeCard,
    MainResidenceTypeCard,
    BuildingTimeDivisionCard,
    LastYearRotationCard,
    MoveInAgeCard,
    SettleSurveyCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
