var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"justify-content-md-center mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('b-avatar',{attrs:{"rounded":"","size":"42","variant":"light-primary"}},[_c('feather-icon',{attrs:{"icon":"TrendingUpIcon","size":"20"}})],1),_c('h4',{staticClass:"my-50"},[_vm._v("Attractivité")]),_c('span',[_vm._v("Dans la section 'Attractivité', vous trouverez les indicateurs nécessaires pour comprendre la dynamique des prix de vente et des loyers du secteur.")])],1)])],1),_c('b-row',{attrs:{"id":"item-2"}},[(_vm.reportData.prixm2ByPeriode.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('square-meters-price-trend-card',_vm._g({attrs:{"reportData":_vm.reportData}},_vm.$listeners))],1):_vm._e()],1),_c('b-row',{attrs:{"id":"item-3"}},[(_vm.reportData.prixm2ByType.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('square-meters-price-type-card',_vm._g({attrs:{"reportData":_vm.reportData}},_vm.$listeners))],1):_vm._e()],1),_c('b-row',{attrs:{"id":"item-4"}},[(_vm.reportData.rentTrend.data && _vm.reportData.rentTrend.data.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('rent-trend-card',_vm._g({attrs:{"reportData":_vm.reportData}},_vm.$listeners))],1):_vm._e()],1),_c('b-row',{attrs:{"id":"item-5"}},[(
        _vm.reportData.rentTrend.data &&
        _vm.reportData.rentTrend.data.length > 0 &&
        _vm.reportData.prixm2ByPeriode.length > 0
      )?_c('b-col',{attrs:{"md":"12"}},[_c('rental-yield-trend-card',_vm._g({attrs:{"reportData":_vm.reportData}},_vm.$listeners))],1):_vm._e()],1),_c('b-row',{attrs:{"id":"item-6"}},[(
        _vm.reportData.ecosystemPrograms &&
        _vm.reportData.ecosystemPrograms.length > 1
      )?_c('b-col',{attrs:{"md":"12"}},[_c('ecosystem-programs-card',_vm._g({attrs:{"reportData":_vm.reportData}},_vm.$listeners))],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }