<template>
  <div class="mt-5">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell(total_investor)="data">
        <span>{{ data.item.total_investor }}</span>
        <b-badge
          pill
          :variant="
            data.item.investor_rate >= 0.5 ? 'light-success' : 'light-secondary'
          "
          class="ml-1"
        >
          {{ percentage(data.item.investor_rate) }}
        </b-badge>
      </template>

      <template #cell(total_resident)="data">
        <span>{{ data.item.total_resident }}</span>
        <b-badge
          pill
          :variant="
            data.item.resident_rate >= 0.5 ? 'light-success' : 'light-secondary'
          "
          class="ml-1"
        >
          {{ percentage(data.item.resident_rate) }}
        </b-badge>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable, BBadge } from "bootstrap-vue";
import { percentage, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const items = [];

    this.ventesByPeriode.data.forEach((element) => {
      items.push({
        year: formatPeriodQuarter(element.period),
        total_investor: element.investments.total_investor,
        investor_rate: element.investments.investor_rate,
        total_resident: element.investments.total_resident,
        resident_rate: element.investments.resident_rate,
      });
    });

    return {
      fields: [
        {
          key: "year",
          label: "Année",
        },
        {
          key: "total_investor",
          label: "Investissement",
        },
        {
          key: "total_resident",
          label: "Résidentiel",
        },
      ],
      items,
    };
  },
  methods: {
    percentage,
  },
};
</script>
