<template>
  <div v-if="reportData.ventesByPeriode.length > 0">
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="ShoppingBagIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Immobilier neuf</h4>
          <span
            >Dans la section 'Immobilier neuf', vous trouverez les indicateurs
            nécessaires pour comprendre la dynamique immobilières du
            secteur.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-7">
      <b-col md="12">
        <sales-volume-trend-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-8">
      <b-col md="12">
        <sales-trend-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-9">
      <b-col md="12">
        <sales-speed-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>

    <b-row id="item-10">
      <b-col md="12">
        <sales-invest-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";
import SalesVolumeTrendCard from "./sales-volume/SalesVolumeTrendCard";
import SalesSpeedCard from "./sales-trend/SalesSpeedCard";
import SalesTrendCard from "./sales-trend/SalesTrendCard";
import SalesInvestCard from "./sales-invest/SalesInvestCard";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    SalesVolumeTrendCard,
    SalesSpeedCard,
    SalesTrendCard,
    SalesInvestCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
