<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { percentage } from "@core/utils/filter";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const workersData = [];

    this.demographyByPeriode.data.forEach((element) => {
      xAxisData.push(element.year);
      workersData.push(element.employment.unemployment_rate);
    });

    return {
      option: {
        xAxisData,
        series: [
          {
            name: "Taux de chômage",
            type: "line",
            showSymbol: true,
            data: workersData,
          },
        ],
        formatter: (params) => {
          return `
                ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${percentage(
            params[0].value
          )}`;
        },
      },
    };
  },
  methods: {
    percentage,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
