<template>
  <b-card title="Santé">
    <b-row>
      <b-col cols="4">
        <healthcare-details :reportData="reportData" />
      </b-col>
      <b-col cols="8">
        <healthcare-map :reportData="reportData" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import HealthcareMap from "./healthcare-elements/HealthcareMap";
import HealthcareDetails from "./healthcare-elements/HealthcareDetails";

export default {
  components: {
    BCard,
    BRow,
    BCol,

    // Components Vue
    HealthcareMap,
    HealthcareDetails,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
