<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
import { formatPeriodQuarter } from "@core/utils/filter";
import { percentage } from "@core/utils/filter";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    rentTrend: {
      type: Object,
      required: true,
    },
    prixm2ByPeriode: {
      type: Object,
      required: true,
    },
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data(props) {
    const xAxisData = [];
    const rentalYieldData = [];
    const rentPinelData = [];

    var i = 1;
    props.prixm2ByPeriode.data.forEach((element) => {
      if (element.price_meter_new_lodgment_without_parking > 0) {
        xAxisData.push(formatPeriodQuarter(element.period));

        const rentalYield =
          ((props.rentTrend.data.find(
            (e) => e.year === element.period.slice(0, 4)
          ).rent_meter *
            12) /
            element.price_meter_new_lodgment_without_parking) *
          100;

        rentalYieldData.push(rentalYield.toFixed(2));
        props.prixm2ByPeriode.data.length === i
          ? rentPinelData.push(
              (
                ((this.ventesByPeriode.pinel.rent * 12) /
                  element.price_meter_new_lodgment_without_parking) *
                100
              ).toFixed(2)
            )
          : rentPinelData.push(null);
        i++;
      }
    });

    return {
      option: {
        xAxisData,
        series: [
          {
            name: "Rendement locatif brut moyen",
            type: "line",
            showSymbol: true,
            lineStyle: {
              width: 3,
            },
            data: rentalYieldData,
          },
          {
            name: "Loyer/m² Pinel",
            type: "line",
            showSymbol: true,
            symbol: "arrow",
            symbolSize: 20,
            lineStyle: {
              width: 3,
            },
            data: rentPinelData,
          },
        ],
        formatter: (params) => {
          var tooltip = `
                Année: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? params[0].value : "-"
          }`;
          if (params.length >= 2) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
                params[1].value ? params[1].value : "-"
              }`;
          }
          return tooltip;
        },
      },
    };
  },
  methods: {
    percentage,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
