<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>
          Offre du promoteur -
          <b-badge variant="light-info">
            <span>
              {{ reportData.program.promoter_name }}
            </span>
          </b-badge>
        </h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet affichage permet de visualiser une sélection de programmes, du même promoteur, à proximité."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row
      v-if="
        reportData.developerPrograms && reportData.developerPrograms.length > 1
      "
    >
      <b-col xl="12">
        <developer-programs-array :reportData="reportData" />
      </b-col>
      <b-col xl="12">
        <developer-programs-map :reportData="reportData" />
      </b-col>
      <b-col xl="12" class="mt-1">
        <comment-form-text-area
          :enable="reportData.comments.programsDeveloper.enable"
          :commentField="reportData.comments.programsDeveloper.value"
          v-on:update:comment="
            reportData.comments.programsDeveloper.value = $event
          "
          v-on:update:enable="
            reportData.comments.programsDeveloper.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center text-center mb-1 mt-2" v-else>
      <b-col>
        <feather-icon icon="LoaderIcon" size="30" />
        <h4 class="mt-2 mb-1">Aucune Offre</h4>
        <p class="card-text">
          Aucun programme disponible à proximité pour le promoteur.
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VBTooltip, BCard, BRow, BCol, BAvatar, BBadge } from "bootstrap-vue";
import DeveloperProgramsArray from "./developer-programs-elements/DeveloperProgramsArray";
import DeveloperProgramsMap from "./developer-programs-elements/DeveloperProgramsMap";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,

    // Components Vue
    DeveloperProgramsArray,
    DeveloperProgramsMap,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
