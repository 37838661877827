<template>
  <div>
    <app-echart-stacked-area :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";

export default {
  components: {
    AppEchartStackedArea,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      option: {
        xAxisData: this.demographyByPeriode.data.map((el) => el.year),
        series: [
          {
            name: "Population",
            type: "line",
            showSymbol: true,
            lineStyle: {
              width: 3,
            },
            data: this.demographyByPeriode.data.map(
              (el) => el.population.population
            ),
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 17rem;
}
</style>
