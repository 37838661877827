<template>
  <section>
    <!-- PDF Content Here -->
    <section class="pdf-item text-center">
      <img
        class="cover-image mt-5"
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Cover Image"
        ref="coverImage"
      />
      <div class="mt-5" v-if="reportData.program">
        <h2>{{ reportData.program.name }}</h2>
        <h5>{{ reportData.program.location.sector_name }}</h5>
        <h3>
          proposé par
          <b-badge variant="light-info">{{
            reportData.program.promoter_name
          }}</b-badge>
        </h3>
        <h5>le {{ formatReverseDate(reportData.createdAt) }}</h5>
      </div>
      <div class="mt-5" v-else>
        <h2>Etude à l'emplacement</h2>
        <h5>le {{ formatReverseDate(reportData.createdAt) }}</h5>
      </div>
    </section>

    <div class="html2pdf__page-break" v-if="reportData.program" />

    <section class="pdf-item" v-if="reportData.program">
      <div class="ml-2 mtop-10px">
        <h3>1. Détails Programme</h3>
      </div>
      <div class="mt-2 ml-3">
        <h4 style="color: #00a1b5">a. Programme</h4>
      </div>
      <program-details
        :program="reportData.program"
        :ventesByPeriode="reportData.ventesByPeriode[0]"
      />
    </section>

    <div
      class="html2pdf__page-break"
      v-if="
        reportData.developerPrograms && reportData.developerPrograms.length > 1
      "
    />

    <section
      class="pdf-item"
      v-if="
        reportData.developerPrograms && reportData.developerPrograms.length > 1
      "
    >
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Offre du promoteur</h4>
      </div>
      <developer-programs-array :reportData="reportData" />
      <developer-programs-map :reportData="reportData" />
      <comment-pdf-text
        v-if="
          reportData.comments.programsDeveloper.value.length > 0 &&
          reportData.comments.programsDeveloper.enable
        "
        :commentField="reportData.comments.programsDeveloper.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">2. Attractivité</h3>
        <h3 v-else>1. Attractivité</h3>
      </div>
      <div class="mt-2 ml-3">
        <h4 style="color: #00a1b5">a. Prix m² par période</h4>
      </div>
      <square-meters-price-chart-trend
        :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
        :program="reportData.program"
      />

      <square-meters-price-array-trend
        :prixm2ByPeriode="reportData.prixm2ByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.squareMetersTrend.value.length > 0 &&
          reportData.comments.squareMetersTrend.enable
        "
        :commentField="reportData.comments.squareMetersTrend.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Prix m² par typologie</h4>
      </div>
      <square-meters-price-chart-type
        :prixm2ByType="reportData.prixm2ByType[0]"
      />
      <square-meters-price-array-type
        :prixm2ByType="reportData.prixm2ByType[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.squareMetersType.value.length > 0 &&
          reportData.comments.squareMetersType.enable
        "
        :commentField="reportData.comments.squareMetersType.value"
      />
    </section>

    <div
      class="html2pdf__page-break"
      v-if="reportData.rentTrend && reportData.rentTrend.length > 1"
    />

    <section
      class="pdf-item"
      v-if="reportData.rentTrend && reportData.rentTrend.length > 1"
    >
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">c. Loyer m²</h4>
      </div>
      <rent-graph :rentTrend="reportData.rentTrend[0]" />
      <rent-array :rentTrend="reportData.rentTrend[0]" />
      <comment-pdf-text
        v-if="
          reportData.comments.rentTrend.value.length > 0 &&
          reportData.comments.rentTrend.enable
        "
        :commentField="reportData.comments.rentTrend.value"
      />
    </section>

    <div
      class="html2pdf__page-break"
      v-if="
        reportData.ecosystemPrograms && reportData.ecosystemPrograms.length > 1
      "
    />

    <section
      class="pdf-item"
      v-if="
        reportData.ecosystemPrograms && reportData.ecosystemPrograms.length > 1
      "
    >
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">d. Autres programmes</h4>
      </div>
      <ecosystem-programs-array :reportData="reportData" />
      <ecosystem-programs-map :reportData="reportData" />
      <comment-pdf-text
        v-if="
          reportData.comments.rentTrend.value.length > 0 &&
          reportData.comments.rentTrend.enable
        "
        :commentField="reportData.comments.rentTrend.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">3. Immobilier neuf</h3>
        <h3 v-else>2. Immobilier neuf</h3>
      </div>
      <div class="mt-2 ml-3 mb-1">
        <h4 style="color: #00a1b5">a. Volumes</h4>
      </div>
      <sales-volume-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <sales-volume-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <comment-pdf-text
        v-if="
          reportData.comments.salesVolume.value.length > 0 &&
          reportData.comments.salesVolume.enable
        "
        :commentField="reportData.comments.salesVolume.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Tendance</h4>
      </div>
      <sales-trend-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <sales-trend-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <comment-pdf-text
        v-if="
          reportData.comments.salesTrend.value.length > 0 &&
          reportData.comments.salesTrend.enable
        "
        :commentField="reportData.comments.salesTrend.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-3 mtop-10px mb-1">
        <h4 style="color: #00a1b5">c. Ecoulement</h4>
      </div>
      <sales-speed-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <sales-speed-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <comment-pdf-text
        v-if="
          reportData.comments.salesSpeed.value.length > 0 &&
          reportData.comments.salesSpeed.enable
        "
        :commentField="reportData.comments.salesSpeed.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">d. Investissements</h4>
      </div>
      <sales-invest-chart :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <sales-invest-array :ventesByPeriode="reportData.ventesByPeriode[0]" />
      <comment-pdf-text
        v-if="
          reportData.comments.salesInvest.value.length > 0 &&
          reportData.comments.salesInvest.enable
        "
        :commentField="reportData.comments.salesInvest.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">4. Logements</h3>
        <h3 v-else>3. Logements</h3>
      </div>
      <div class="mt-2 ml-3 mb-1">
        <h4 style="color: #00a1b5">a. Répartition des logements</h4>
      </div>
      <logement-type-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.logementType.value.length > 0 &&
          reportData.comments.logementType.enable
        "
        :commentField="reportData.comments.logementType.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">
          b. Répartition des résidences principales par type
        </h4>
      </div>
      <main-residence-type-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <main-residence-type-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.salesInvest.value.length > 0 &&
          reportData.comments.salesInvest.enable
        "
        :commentField="reportData.comments.salesInvest.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-3 mtop-10px mb-1">
        <h4 style="color: #00a1b5">
          c. Période d'achèvement des résidences principales
        </h4>
      </div>
      <building-time-division-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <building-time-division-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
        class="mt-3"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.buildingTimeDivision.value.length > 0 &&
          reportData.comments.buildingTimeDivision.enable
        "
        :commentField="reportData.comments.buildingTimeDivision.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">d. Lieu de résidence un an auparavant</h4>
      </div>
      <last-year-rotation-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.lastYearRotation.value.length > 0 &&
          reportData.comments.lastYearRotation.enable
        "
        :commentField="reportData.comments.lastYearRotation.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">
          e. Ancienneté d'emménagement dans la résidence principale
        </h4>
      </div>
      <move-in-age-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.moveInAge.value.length > 0 &&
          reportData.comments.moveInAge.enable
        "
        :commentField="reportData.comments.moveInAge.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">f. Occupation des logements</h4>
      </div>
      <settle-survey-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <shelters-survey-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.settleSurvey.value.length > 0 &&
          reportData.comments.settleSurvey.enable
        "
        :commentField="reportData.comments.settleSurvey.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">4. Economie</h3>
        <h3 v-else>3. Economie</h3>
      </div>

      <div class="mt-2 ml-3">
        <h4 style="color: #00a1b5">a. Catégories socioprofessionnelles</h4>
      </div>
      <employment-division-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <employment-division-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.employmentDivision.value.length > 0 &&
          reportData.comments.employmentDivision.enable
        "
        :commentField="reportData.comments.employmentDivision.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Emploi & Chômage</h4>
      </div>
      <employments-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <employments-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.employments.value.length > 0 &&
          reportData.comments.employments.enable
        "
        :commentField="reportData.comments.employments.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">5. Sociodémographie</h3>
        <h3 v-else>4. Sociodémographie</h3>
      </div>

      <div class="mt-2 ml-3">
        <h4 style="color: #00a1b5">a. Population</h4>
      </div>
      <population-trend-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <population-trend-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.populationTrend.value.length > 0 &&
          reportData.comments.populationTrend.enable
        "
        :commentField="reportData.comments.populationTrend.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Répartition</h4>
      </div>
      <people-division-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.peopleDivision.value.length > 0 &&
          reportData.comments.peopleDivision.enable
        "
        :commentField="reportData.comments.peopleDivision.value"
      />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">c. Composition des ménages</h4>
      </div>
      <household-division-chart
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <household-division-array
        :demographyByPeriode="reportData.demographyByPeriode[0]"
      />
      <comment-pdf-text
        v-if="
          reportData.comments.householdDivision.value.length > 0 &&
          reportData.comments.householdDivision.enable
        "
        :commentField="reportData.comments.householdDivision.value"
      />
    </section>
    <!-- 
    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="ml-2 mtop-10px">
        <h3 v-if="reportData.program">6. Cadre de Vie</h3>
        <h3 v-else>5. Cadre de Vie</h3>
      </div>

      <div class="mt-2 ml-3">
        <h4 style="color: #00a1b5">a. Mobilité</h4>
      </div>
      <mobility-details :reportData="reportData" />
      <mobility-map :reportData="reportData" />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">b. Santé</h4>
      </div>
      <healthcare-details :reportData="reportData" />
      <healthcare-map :reportData="reportData" />
    </section>

    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="mtop-10px ml-3">
        <h4 style="color: #00a1b5">c. Éducation</h4>
      </div>
      <education-details :reportData="reportData" />
      <education-map :reportData="reportData" />
    </section> -->
  </section>
</template>

<script>
import { BBadge } from "bootstrap-vue";

// Custom formater
import { formatReverseDate } from "@core/utils/filter";

// Elements for pdf extract
import ProgramDetails from "./program/description/ProgramDetails.vue";
import DeveloperProgramsArray from "./program/developer-programs/developer-programs-elements/DeveloperProgramsArray.vue";
import DeveloperProgramsMap from "./program/developer-programs/developer-programs-elements/DeveloperProgramsMap";
import SquareMetersPriceChartTrend from "./attractivity/square-meter-price/trend-elements/SquareMetersPriceChartTrend";
import SquareMetersPriceArrayTrend from "./attractivity/square-meter-price/trend-elements/SquareMetersPriceArrayTrend";
import SquareMetersPriceChartType from "./attractivity/square-meter-price/type-elements/SquareMetersPriceChartType";
import SquareMetersPriceArrayType from "./attractivity/square-meter-price/type-elements/SquareMetersPriceArrayType";
import RentGraph from "./attractivity/rent/trend-elements/RentGraph";
import RentArray from "./attractivity/rent/trend-elements/RentArray";
import EcosystemProgramsArray from "./attractivity/ecosystem-programs/ecosystem-elements/EcosystemProgramsArray";
import EcosystemProgramsMap from "./attractivity/ecosystem-programs/ecosystem-elements/EcosystemProgramsMap";
import SalesVolumeChart from "./business/sales-volume/trend-elements/SalesVolumeChart";
import SalesVolumeArray from "./business/sales-volume/trend-elements/SalesVolumeArray";
import SalesSpeedChart from "./business/sales-trend/sales-speed-elements/SalesSpeedChart";
import SalesSpeedArray from "./business/sales-trend/sales-speed-elements/SalesSpeedArray";
import SalesTrendChart from "./business/sales-trend/sales-trend-elements/SalesTrendChart";
import SalesTrendArray from "./business/sales-trend/sales-trend-elements/SalesTrendArray";
import SalesInvestChart from "./business/sales-invest/sales-invest-elements/SalesInvestChart";
import SalesInvestArray from "./business/sales-invest/sales-invest-elements/SalesInvestArray";
import LogementTypeChart from "./logement/logement-type/logement-type-elements/LogementTypeChart";
import MainResidenceTypeChart from "./logement/main-residence-type/main-residence-type-elements/MainResidenceTypeChart";
import MainResidenceTypeArray from "./logement/main-residence-type/main-residence-type-elements/MainResidenceTypeArray";
import BuildingTimeDivisionChart from "./logement/building-time-division/building-time-division-elements/BuildingTimeDivisionChart";
import BuildingTimeDivisionArray from "./logement/building-time-division/building-time-division-elements/BuildingTimeDivisionArray";
import LastYearRotationChart from "./logement/last-year-rotation/last-year-rotation-elements/LastYearRotationChart";
import MoveInAgeChart from "./logement/move-in-age/move-in-age-elements/MoveInAgeChart";
import SettleSurveyChart from "./logement/settle-survey/settle-survey-elements/SettleSurveyChart";
import SheltersSurveyChart from "./logement/settle-survey/settle-survey-elements/SheltersSurveyChart";
import EmploymentDivisionChart from "./economy/employment-division/employment-division-elements/EmploymentDivisionChart";
import EmploymentDivisionArray from "./economy/employment-division/employment-division-elements/EmploymentDivisionArray";
import EmploymentsChart from "./economy/employments/employments-elements/EmploymentsChart";
import EmploymentsArray from "./economy/employments/employments-elements/EmploymentsArray";
import PopulationTrendChart from "./sociodemography/population-trend/population-trend-elements/PopulationTrendChart";
import PopulationTrendArray from "./sociodemography/population-trend/population-trend-elements/PopulationTrendArray";
import PeopleDivisionChart from "./sociodemography/people-division/people-division-elements/PeopleDivisionChart";
import HouseholdDivisionChart from "./sociodemography/household-division/household-division-elements/HouseholdDivisionChart";
import HouseholdDivisionArray from "./sociodemography/household-division/household-division-elements/HouseholdDivisionArray";
/* import MobilityMap from "./cadredevie/mobility/mobility-elements/MobilityMap";
import MobilityDetails from "./cadredevie/mobility/mobility-elements/MobilityDetails";
import HealthcareMap from "./cadredevie/healthcare/healthcare-elements/HealthcareMap";
import HealthcareDetails from "./cadredevie/healthcare/healthcare-elements/HealthcareDetails";
import EducationMap from "./cadredevie/education/education-elements/EducationMap";
import EducationDetails from "./cadredevie/education/education-elements/EducationDetails"; */
import CommentPdfText from "./shared/CommentPdfText";

export default {
  components: {
    BBadge,

    // PDF
    ProgramDetails,
    DeveloperProgramsArray,
    DeveloperProgramsMap,
    SquareMetersPriceChartTrend,
    SquareMetersPriceArrayTrend,
    SquareMetersPriceChartType,
    SquareMetersPriceArrayType,
    RentGraph,
    RentArray,
    EcosystemProgramsArray,
    EcosystemProgramsMap,
    SalesVolumeChart,
    SalesVolumeArray,
    SalesSpeedChart,
    SalesSpeedArray,
    SalesTrendChart,
    SalesTrendArray,
    SalesInvestChart,
    SalesInvestArray,
    LogementTypeChart,
    MainResidenceTypeChart,
    MainResidenceTypeArray,
    BuildingTimeDivisionChart,
    BuildingTimeDivisionArray,
    LastYearRotationChart,
    MoveInAgeChart,
    HouseholdDivisionChart,
    HouseholdDivisionArray,
    EmploymentDivisionChart,
    EmploymentDivisionArray,
    EmploymentsChart,
    EmploymentsArray,
    PopulationTrendChart,
    PopulationTrendArray,
    PeopleDivisionChart,
    SettleSurveyChart,
    SheltersSurveyChart,
    /* MobilityMap,
    MobilityDetails,
    HealthcareMap,
    HealthcareDetails,
    EducationMap,
    EducationDetails, */
    CommentPdfText,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatReverseDate,
  },
};
</script>

<style>
.pdf-item {
  font-size: 11px;
}

.cover-image {
  text-align: center;
  height: 12rem;
  width: auto;
}

.mtop-10px {
  margin-top: 10px;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}
</style>
