<template>
  <div class="mt-1">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell()="data">
        <div v-if="data.value.price >= 0">
          {{ data.value.price }} mois
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate > 0"
            pill
            variant="light-success"
            class="ml-1"
          >
            +{{ data.value.rate }}%
          </b-badge>
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate < 0"
            pill
            variant="light-secondary"
            class="ml-1"
          >
            {{ data.value.rate }}%
          </b-badge>
        </div>
        <div v-else>{{ data.value }}</div>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable, BBadge } from "bootstrap-vue";
import { percentage, evalTrend, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const items = [];

    var lastElem = null;
    this.ventesByPeriode.data.forEach((element) => {
      if (lastElem != null) {
        items.push({
          year: formatPeriodQuarter(element.period),
          average_rhythm: {
            price: element.rhythm.average_rhythm,
            rate: evalTrend(
              element.rhythm.average_rhythm,
              lastElem.rhythm.average_rhythm
            ),
          },
        });
      } else {
        items.push({
          year: formatPeriodQuarter(element.period),
          average_rhythm: {
            price: element.rhythm.average_rhythm,
            rate: 0,
          },
        });
      }
      lastElem = element;
    });

    return {
      fields: [
        {
          key: "year",
          label: "Année",
        },
        {
          key: "average_rhythm",
          label: "Durée moyenne de commercialisation",
        },
      ],
      items,
    };
  },
  methods: {
    percentage,
  },
};
</script>
