<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Prix m² par typologie</h4>
      </b-col>
      <b-col cols="1" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre la ventilation des prix au m² par typologie
            de logement neuf et ancien sur la dernière année."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <square-meters-price-chart-type
          :prixm2ByType="reportData.prixm2ByType[0]"
          :program="reportData.program"
        />
      </b-col>
      <b-col xl="12">
        <square-meters-price-array-type
          :prixm2ByType="reportData.prixm2ByType[0]"
          :program="reportData.program"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.squareMetersType.enable"
          :commentField="reportData.comments.squareMetersType.value"
          v-on:update:comment="
            reportData.comments.squareMetersType.value = $event
          "
          v-on:update:enable="
            reportData.comments.squareMetersType.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VBTooltip, BCard, BRow, BCol, BAvatar } from "bootstrap-vue";
import SquareMetersPriceChartType from "./type-elements/SquareMetersPriceChartType";
import SquareMetersPriceArrayType from "./type-elements/SquareMetersPriceArrayType";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    SquareMetersPriceChartType,
    SquareMetersPriceArrayType,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
