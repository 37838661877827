<template>
  <div>
    <app-echart-doughnut :series="series" style="width: 100%" />

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee</small
      >
    </div>
  </div>
</template>

<script>
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";

export default {
  components: {
    AppEchartDoughnut,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: "Répartition des ménages",
          type: "pie",
          radius: ["45%", "65%"],
          avoidLabelOverlap: true,
          tooltip: {
            formatter: "{a} <br/>{b} : {d}%",
          },
          label: {
            show: true,
            formatter: "{name|{b}}\n{percent|{d}%}",
            minMargin: 5,
            edgeDistance: 10,
            fontSize: 14,
            lineHeight: 15,
            rich: {
              percent: {
                fontSize: 12,
                color: "#999",
              },
            },
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 15,
            maxSurfaceAngle: 50,
          },
          data: [
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_0_14,
              name: "0 à 14 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_15_29,
              name: "15 à 29 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_30_44,
              name: "30 à 44 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_45_59,
              name: "45 à 59 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_60_74,
              name: "60 à 74 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_75_89,
              name: "75 à 89 ans",
            },
            {
              value:
                this.demographyByPeriode.data[
                  this.demographyByPeriode.data.length - 1
                ].population.age_division.age_90_more,
              name: "90 ans ou +",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
