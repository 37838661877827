<template>
  <div>
    <app-echart-bar :option-data="option" class="type-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { currency, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    prixm2ByType: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: false,
    },
  },
  data() {
    const data = this.prixm2ByType.data[this.prixm2ByType.data.length - 1];

    const series = [
      {
        name:
          "Prix/m² moyen logement neuf (avec parking) à proximité au " +
          formatPeriodQuarter(data.period),
        type: "bar",
        data: [
          data.typology.t1.price_meter_new_lodgment_with_parking,
          data.typology.t2.price_meter_new_lodgment_with_parking,
          data.typology.t3.price_meter_new_lodgment_with_parking,
          data.typology.t4.price_meter_new_lodgment_with_parking,
          data.typology.t5.price_meter_new_lodgment_with_parking,
        ],
        barMaxWidth: "13%",
        barMinWidth: "10px",
      },
      {
        name:
          "Prix/m² moyen logement neuf (sans parking) à proximité au " +
          formatPeriodQuarter(data.period),
        type: "bar",
        data: [
          data.typology.t1.price_meter_new_lodgment_without_parking,
          data.typology.t2.price_meter_new_lodgment_without_parking,
          data.typology.t3.price_meter_new_lodgment_without_parking,
          data.typology.t4.price_meter_new_lodgment_without_parking,
          data.typology.t5.price_meter_new_lodgment_without_parking,
        ],
        barMaxWidth: "13%",
        barMinWidth: "10px",
      },
    ];

    for (let index = 1; index <= this.prixm2ByType.data.length; index++) {
      const data =
        this.prixm2ByType.data[this.prixm2ByType.data.length - index];
      if (
        data.typology.t1.price_meter_old_lodgment === 0 &&
        data.typology.t2.price_meter_old_lodgment === 0 &&
        data.typology.t3.price_meter_old_lodgment === 0 &&
        data.typology.t4.price_meter_old_lodgment === 0 &&
        data.typology.t5.price_meter_old_lodgment === 0
      ) {
        continue;
      }
      series.push({
        name:
          "Prix/m² moyen logement ancien à proximité au " +
          formatPeriodQuarter(data.period),
        type: "bar",
        data: [
          data.typology.t1.price_meter_old_lodgment,
          data.typology.t2.price_meter_old_lodgment,
          data.typology.t3.price_meter_old_lodgment,
          data.typology.t4.price_meter_old_lodgment,
          data.typology.t5.price_meter_old_lodgment,
        ],
        barMaxWidth: "13%",
        barMinWidth: "10px",
      });
      break;
    }

    if (this.program) {
      series.push({
        name: "Prix/m² moyen du programme sélectionné (avec parking)",
        type: "bar",
        data: [
          this.program.t1.full_vat_price.price_parking_included.avg
            ? this.program.t1.full_vat_price.price_parking_included.avg /
              this.program.t1.living_area.avg
            : null,
          this.program.t2.full_vat_price.price_parking_included.avg
            ? this.program.t2.full_vat_price.price_parking_included.avg /
              this.program.t2.living_area.avg
            : null,
          this.program.t3.full_vat_price.price_parking_included.avg
            ? this.program.t3.full_vat_price.price_parking_included.avg /
              this.program.t3.living_area.avg
            : null,
          this.program.t4.full_vat_price.price_parking_included.avg
            ? this.program.t4.full_vat_price.price_parking_included.avg /
              this.program.t4.living_area.avg
            : null,
          this.program.t5.full_vat_price.price_parking_included.avg
            ? this.program.t5.full_vat_price.price_parking_included.avg /
              this.program.t5.living_area.avg
            : null,
        ],
        barMaxWidth: "13%",
        barMinWidth: "10px",
      });
      series.push({
        name: "Prix/m² moyen du programme sélectionné (sans parking)",
        type: "bar",
        data: [
          this.program.t1.full_vat_price.price_parking_excepted.avg
            ? this.program.t1.full_vat_price.price_parking_excepted.avg /
              this.program.t1.living_area.avg
            : null,
          this.program.t2.full_vat_price.price_parking_excepted.avg
            ? this.program.t2.full_vat_price.price_parking_excepted.avg /
              this.program.t2.living_area.avg
            : null,
          this.program.t3.full_vat_price.price_parking_excepted.avg
            ? this.program.t3.full_vat_price.price_parking_excepted.avg /
              this.program.t3.living_area.avg
            : null,
          this.program.t4.full_vat_price.price_parking_excepted.avg
            ? this.program.t4.full_vat_price.price_parking_excepted.avg /
              this.program.t4.living_area.avg
            : null,
          this.program.t5.full_vat_price.price_parking_excepted.avg
            ? this.program.t5.full_vat_price.price_parking_excepted.avg /
              this.program.t5.living_area.avg
            : null,
        ],
        barMaxWidth: "13%",
        barMinWidth: "10px",
      });
    }

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: ["T1", "T2", "T3", "T4", "T5+"],
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              type: "dashed",
              color: "#B8B8B8",
            },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        formatter: (params) => {
          var tooltip = `
                Type: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? currency(params[0].value) : "-"
          }`;
          if (params.length >= 2) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
                params[1].value ? currency(params[1].value) : "-"
              }`;
          }
          if (params.length >= 3) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[2].color ? params[2].color : null
                }"></span>${params[2].seriesName}: ${
                params[2].value ? currency(params[2].value) : "-"
              }`;
          }
          if (params.length >= 4) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[3].color ? params[3].color : null
                }"></span>${params[3].seriesName}: ${
                params[3].value ? currency(params[3].value) : "-"
              }`;
          }
          if (params.length >= 5) {
            tooltip =
              tooltip +
              `<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[4].color ? params[4].color : null
                }"></span>${params[4].seriesName}: ${
                params[4].value ? currency(params[4].value) : "-"
              }`;
          }
          return tooltip;
        },
        series,
      },
    };
  },
  methods: {
    currency,
  },
};
</script>

<style lang="scss" scoped>
.type-chart {
  width: 100%;
  height: 21rem;
}
</style>
