<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive>
      <template #cell()="data">
        <div v-if="data.value.price >= 0">
          {{ currency(data.value.price) }}
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate > 0"
            pill
            variant="light-success"
            class="ml-1"
          >
            +{{ data.value.rate }}%
          </b-badge>
          <b-badge
            v-if="data.value.rate !== 0 && data.value.rate < 0"
            pill
            variant="light-secondary"
            class="ml-1"
          >
            {{ data.value.rate }}%
          </b-badge>
        </div>
        <div v-else>
          <span
            :style="{
              display: 'inline-block',
              'margin-right': '5px',
              'border-radius': '10px',
              width: '10px',
              height: '10px',
              'background-color': `${data.value.color}`,
            }"
          ></span
          >{{ data.value.title }}
        </div>
      </template>
    </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Adéquation</small
      >
    </div>
  </div>
</template>

<script>
import { BTable, BBadge } from "bootstrap-vue";
import { currency, evalTrend, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    BTable,
    BBadge,
  },
  props: {
    prixm2ByPeriode: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: false,
    },
  },
  data() {
    const fields = [
      {
        key: "label",
        label: "Catégorie",
      },
    ];

    const newLodgmentWithParkingItem = {
      label: {
        title: "Prix/m² moyen logement neuf à proximité (avec parking)",
        color: "#94D1BE",
      },
    };

    const newLodgmentWithoutParkingItem = {
      label: {
        title: "Prix/m² moyen logement neuf à proximité (sans parking)",
        color: "#c8ad7f",
      },
    };

    const oldLodgmentItem = {
      label: {
        title: "Prix/m² moyen logement ancien à proximité",
        color: "#00a1b5",
      },
    };

    const selectedProgramParkingItem = {
      label: {
        title: "Prix/m² moyen programme sélectionné (avec parking)",
        color: "#BE94D1",
      },
    };

    const selectedProgramItem = {
      label: {
        title: "Prix/m² moyen programme sélectionné (sans parking)",
        color: "#FF934F",
      },
    };

    var lastElem = {};
    this.prixm2ByPeriode.data.forEach((element) => {
      fields.push({
        key: element.period,
        label: formatPeriodQuarter(element.period),
      });
      if (lastElem.period !== "") {
        newLodgmentWithParkingItem[element.period] = {
          price: element.price_meter_new_lodgment_with_parking,
          rate: evalTrend(
            element.price_meter_new_lodgment_with_parking,
            lastElem.price_meter_new_lodgment_with_parking
          ),
        };
        newLodgmentWithoutParkingItem[element.period] = {
          price: element.price_meter_new_lodgment_without_parking,
          rate: evalTrend(
            element.price_meter_new_lodgment_without_parking,
            lastElem.price_meter_new_lodgment_without_parking
          ),
        };
        oldLodgmentItem[element.period] = {
          price: element.price_meter_old_lodgment,
          rate: evalTrend(
            element.price_meter_old_lodgment,
            lastElem.price_meter_old_lodgment
          ),
        };
      } else {
        newLodgmentWithParkingItem[element.period] = {
          price: element.price_meter_new_lodgment_with_parking,
          rate: 0,
        };
        newLodgmentWithoutParkingItem[element.period] = {
          price: element.price_meter_new_lodgment_without_parking,
          rate: 0,
        };
        oldLodgmentItem[element.period] = {
          price: element.price_meter_old_lodgment,
          rate: 0,
        };
      }
      selectedProgramParkingItem[element.period] = {
        price: 0,
        rate: 0,
      };
      selectedProgramItem[element.period] = {
        price: 0,
        rate: 0,
      };
      lastElem = element;
    });
    if (this.program) {
      selectedProgramParkingItem[lastElem.period].price =
        this.program.price.full_vat_price_per_meter_parking_included;
      selectedProgramItem[lastElem.period].price =
        this.program.price.full_vat_price_per_meter_parking_excepted;
    }

    const items = this.program
      ? [
          newLodgmentWithParkingItem,
          newLodgmentWithoutParkingItem,
          oldLodgmentItem,
          selectedProgramParkingItem,
          selectedProgramItem,
        ]
      : [
          newLodgmentWithParkingItem,
          newLodgmentWithoutParkingItem,
          oldLodgmentItem,
        ];

    return {
      fields,
      items,
    };
  },
  methods: {
    currency,
    evalTrend,
  },
};
</script>
