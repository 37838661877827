<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Répartition des résidences principales par type</h4>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre la répartition des résidences principales par type dans la commune du programme ou adresse sélectionné."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="7">
        <main-residence-type-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="5">
        <main-residence-type-array
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.mainResidenceType.enable"
          :commentField="reportData.comments.mainResidenceType.value"
          v-on:update:comment="
            reportData.comments.mainResidenceType.value = $event
          "
          v-on:update:enable="
            reportData.comments.mainResidenceType.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import MainResidenceTypeChart from "./main-residence-type-elements/MainResidenceTypeChart";
import MainResidenceTypeArray from "./main-residence-type-elements/MainResidenceTypeArray";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    MainResidenceTypeChart,
    MainResidenceTypeArray,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.type-chart {
  width: 100%;
  height: 21rem;
}
</style>
