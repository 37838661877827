<template>
  <div>
    <app-echart-bar :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { currency, formatPeriodQuarter } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    ventesByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const xAxisData = [];
    const salesData = [];
    const offersData = [];

    this.ventesByPeriode.data.forEach((element) => {
      xAxisData.push(formatPeriodQuarter(element.period));
      salesData.push(element.new_lodgment_trends.price_per_meter_sales);
      offersData.push(
        element.new_lodgment_trends.price_per_meter_available_offer
      );
    });

    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            lineStyle: {
              type: "dashed",
              color: "#B8B8B8",
            },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "Prix/m² moyen de vente",
            type: "bar",
            data: salesData,
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
          {
            name: "Prix/m² moyen de l'offre",
            type: "bar",
            data: offersData,
            barMaxWidth: "13%",
            barMinWidth: "10px",
          },
        ],
        formatter: (params) => {
          return `
                Année: ${params[0].name}<hr size=1 style="margin: 3px 0">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[0].color ? params[0].color : null
                }"></span>${params[0].seriesName}: ${
            params[0].value ? currency(params[0].value) : "-"
          }<br />
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[1].color ? params[1].color : null
                }"></span>${params[1].seriesName}: ${
            params[1].value ? currency(params[1].value) : "-"
          }`;
        },
      },
    };
  },
  methods: {
    currency,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
