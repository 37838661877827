<template>
  <div class="mt-1">
    <app-echart-bar :option-data="option" class="trend-chart" />
  </div>
</template>

<script>
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { crossProduct } from "@core/utils/filter";

export default {
  components: {
    AppEchartBar,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    const data =
      this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
        .lodgment.division.household.division;

    const total =
      data.family_division.with_child +
      data.family_division.without_child +
      data.family_division.single_parent +
      data.single_person_division.woman +
      data.single_person_division.man +
      data.family_division.other;

    return {
      total,
      option: {
        xAxis: [
          {
            type: "category",
            data: [
              "Ménages avec familles",
              "Ménages d'une personne",
              "Autres ménages",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        formatter: (params) => {
          var label = `
                ${params[0].name}<hr size=1 style="margin: 3px 0">`;

          for (let index = 0; index < params.length; index++) {
            if (params[index].value > 0) {
              label += `
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  params[index].color ? params[index].color : null
                }"></span>${params[index].seriesName}: ${
                params[index].value
              } (${crossProduct(params[index].value, total)}%)<br />`;
            }
          }

          return label;
        },
        grid: {
          left: "60px",
          right: "50px",
          bottom: "20px",
          top: "50px",
        },
        series: [
          {
            name: "Couples avec enfant(s)",
            type: "bar",
            stack: "advertising",
            data: [data.family_division.with_child, 0, 0],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Couples sans enfant",
            type: "bar",
            stack: "advertising",
            data: [data.family_division.without_child, 0, 0],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Familles monoparentales",
            type: "bar",
            stack: "advertising",
            data: [data.family_division.single_parent, 0, 0],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Femmes seules",
            type: "bar",
            stack: "advertising",
            data: [0, data.single_person_division.woman, 0],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Hommes seuls",
            type: "bar",
            stack: "advertising",
            data: [0, data.single_person_division.man, 0],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
          {
            name: "Autres ménages",
            type: "bar",
            stack: "advertising",
            data: [0, 0, data.family_division.other],
            barMaxWidth: "40%",
            barMinWidth: "20px",
          },
        ],
      },
    };
  },
  methods: {
    crossProduct,
  },
};
</script>

<style lang="scss" scoped>
.trend-chart {
  width: 100%;
  height: 21rem;
}
</style>
