<template>
  <div class="mt-2">
    <b-table :items="items" :fields="fields" small responsive> </b-table>

    <div
      class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 mt-1"
    >
      <small></small>
      <small class="timeline-item-time text-nowrap text-muted ml-1"
        >Source: Insee
        {{
          this.demographyByPeriode.data[
            this.demographyByPeriode.data.length - 1
          ].year
        }}</small
      >
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { crossProduct } from "@core/utils/filter";

export default {
  components: {
    BTable,
  },
  props: {
    demographyByPeriode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "label",
          label: "Typologie",
        },
        {
          key: "value",
          label: "Volume",
        },
        {
          key: "rate",
          label: "Taux",
        },
      ],
    };
  },
  computed: {
    items() {
      const roomDivision =
        this.demographyByPeriode.data[this.demographyByPeriode.data.length - 1]
          .lodgment.division.main_lodgment_rooms_division;

      const total =
        roomDivision.t1 +
        roomDivision.t2 +
        roomDivision.t3 +
        roomDivision.t4 +
        roomDivision.t5;

      return [
        {
          label: "T1",
          value: roomDivision.t1,
          rate: crossProduct(roomDivision.t1, total) + "%",
        },
        {
          label: "T2",
          value: roomDivision.t2,
          rate: crossProduct(roomDivision.t2, total) + "%",
        },
        {
          label: "T3",
          value: roomDivision.t3,
          rate: crossProduct(roomDivision.t3, total) + "%",
        },
        {
          label: "T4",
          value: roomDivision.t4,
          rate: crossProduct(roomDivision.t4, total) + "%",
        },
        {
          label: "T5",
          value: roomDivision.t5,
          rate: crossProduct(roomDivision.t5, total) + "%",
        },
      ];
    },
  },
};
</script>
