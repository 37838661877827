<template>
  <b-card>
    <b-row align-h="between">
      <b-col>
        <h4>Répartition de la population</h4>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-avatar
          size="32"
          variant="light-primary"
          v-b-tooltip.hover.v-primary
          title="Cet indicateur illustre la répartition de la population par tranche d’âge sur le secteur du programme ou adresse sélectionné au dernier recensement INSEE."
        >
          <feather-icon size="20" icon="HelpCircleIcon" />
        </b-avatar>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <people-division-chart
          :demographyByPeriode="reportData.demographyByPeriode[0]"
        />
      </b-col>
      <b-col xl="12">
        <comment-form-text-area
          :enable="reportData.comments.peopleDivision.enable"
          :commentField="reportData.comments.peopleDivision.value"
          v-on:update:comment="
            reportData.comments.peopleDivision.value = $event
          "
          v-on:update:enable="
            reportData.comments.peopleDivision.enable = $event
          "
          v-on="$listeners"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, VBTooltip, BAvatar } from "bootstrap-vue";
import PeopleDivisionChart from "./people-division-elements/PeopleDivisionChart";
import CommentFormTextArea from "../../shared/CommentFormTextArea";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,

    // Components Vue
    PeopleDivisionChart,
    CommentFormTextArea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.type-chart {
  width: 100%;
  height: 21rem;
}
</style>
