import { ref } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const useReportData = () => {
  // Use toast
  const toast = useToast();

  const reportData = ref({
    id: null,
    isBusy: false,
    center: {},
    // Overpass datas
    busStops: [],
    tramStops: [],
    subwayStops: [],
    doctors: [],
    clinics: [],
    hospitals: [],
    pharmacies: [],
    nursingHomes: [],
    nurseries: [],
    primarySchools: [],
    secondarySchools: [],
    superiorSchools: [],
    // Report datas
    createdAt: new Date(),
    program: {},
    ecosystemPrograms: [],
    developerPrograms: [],
    rentTrend: [],
    prixm2ByPeriode: [],
    prixm2ByType: [],
    ventesByPeriode: [],
    demographyByPeriode: [],
    // Comments datas
    comments: {
      squareMetersTrend: { value: "", enable: false },
      squareMetersType: { value: "", enable: false },
      programsEcosystem: { value: "", enable: false },
      rentalPinelYield: { value: "", enable: false },
      programsDeveloper: { value: "", enable: false },
      rentTrend: { value: "", enable: false },
      rentalYieldTrend: { value: "", enable: false },
      settleSurvey: { value: "", enable: false },
      salesVolume: { value: "", enable: false },
      salesTrend: { value: "", enable: false },
      salesSpeed: { value: "", enable: false },
      salesInvest: { value: "", enable: false },
      buildingTimeDivision: { value: "", enable: false },
      householdDivision: { value: "", enable: false },
      lastYearRotation: { value: "", enable: false },
      logementType: { value: "", enable: false },
      mainResidenceType: { value: "", enable: false },
      moveInAge: { value: "", enable: false },
      employments: { value: "", enable: false },
      employmentDivision: { value: "", enable: false },
      populationTrend: { value: "", enable: false },
      peopleDivision: { value: "", enable: false },
    },
  });

  const fetchSavedReportData = (programID) => {
    reportData.value.isBusy = true;
    store
      .dispatch("app-decimmo/findReportByID", programID)
      .then((response) => {
        const program = JSON.parse(response.data.program);
        const center = {
          latitude: Number(program.location.latitude),
          longitude: Number(program.location.longitude),
        };
        reportData.value.id = response.data.ID;
        reportData.value.createdAt = response.data.created_at;
        reportData.value.program = program;
        reportData.value.center = center;
        reportData.value.comments = JSON.parse(response.data.comments);
        reportData.value.ecosystemPrograms = JSON.parse(
          response.data.ecosystemPrograms
        );
        reportData.value.developerPrograms = JSON.parse(
          response.data.developerPrograms
        );
        reportData.value.prixm2ByPeriode = JSON.parse(
          response.data.prixm2ByPeriode
        );
        reportData.value.rentTrend = JSON.parse(response.data.rentm2ByPeriode);
        reportData.value.prixm2ByType = JSON.parse(response.data.prixm2ByType);
        reportData.value.ventesByPeriode = JSON.parse(
          response.data.ventesByPeriode
        );
        reportData.value.demographyByPeriode = JSON.parse(
          response.data.demographyByPeriode
        );
        reportData.value.isBusy = false;
      })
      .catch(() => {
        reportData.value.isBusy = false;
      });
  };

  const updateReport = () => {
    const updateReport = {
      id: reportData.value.id,
      program: JSON.stringify(reportData.value.program),
      comments: JSON.stringify(reportData.value.comments),
      ecosystemPrograms: JSON.stringify(reportData.value.ecosystemPrograms),
      developerPrograms: JSON.stringify(reportData.value.developerPrograms),
      rentm2ByPeriode: JSON.stringify(reportData.value.rentTrend),
      prixm2ByPeriode: JSON.stringify(reportData.value.prixm2ByPeriode),
      prixm2ByType: JSON.stringify(reportData.value.prixm2ByType),
      ventesByPeriode: JSON.stringify(reportData.value.ventesByPeriode),
      demographyByPeriode: JSON.stringify(reportData.value.demographyByPeriode),
    };

    store.dispatch("app-decimmo/updateReport", updateReport).then(() => {
      toast(
        {
          component: ToastificationContent,
          props: {
            title: "Rapport enregistré avec succès",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        },
        { position: "top-center" }
      );
    });
  };

  return {
    // data
    reportData,

    // methods
    updateReport,
    fetchSavedReportData,
  };
};
