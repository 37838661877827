<template>
  <div class="mt-2 mx-2">
    <label class="font-weight-bolder text-primary">Commentaire : </label>
    <span>
      {{ commentField }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    commentField: {
      type: String,
      required: true,
    },
  },
};
</script>
