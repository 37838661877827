import { render, staticRenderFns } from "./LastYearRotationCard.vue?vue&type=template&id=2f3eccc1&scoped=true&"
import script from "./LastYearRotationCard.vue?vue&type=script&lang=js&"
export * from "./LastYearRotationCard.vue?vue&type=script&lang=js&"
import style0 from "./LastYearRotationCard.vue?vue&type=style&index=0&id=2f3eccc1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3eccc1",
  null
  
)

export default component.exports