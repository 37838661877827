<template>
  <section>
    <b-row class="justify-content-md-center mb-2">
      <b-col cols="6">
        <div class="text-center">
          <b-avatar rounded size="42" variant="light-primary">
            <feather-icon icon="TrendingUpIcon" size="20" />
          </b-avatar>
          <h4 class="my-50">Attractivité</h4>
          <span
            >Dans la section 'Attractivité', vous trouverez les indicateurs
            nécessaires pour comprendre la dynamique des prix de vente et des
            loyers du secteur.</span
          >
        </div>
      </b-col>
    </b-row>

    <b-row id="item-2">
      <b-col md="12" v-if="reportData.prixm2ByPeriode.length > 0">
        <square-meters-price-trend-card
          :reportData="reportData"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
    <b-row id="item-3">
      <b-col md="12" v-if="reportData.prixm2ByType.length > 0">
        <square-meters-price-type-card
          :reportData="reportData"
          v-on="$listeners"
        />
      </b-col>
    </b-row>
    <b-row id="item-4">
      <b-col
        md="12"
        v-if="reportData.rentTrend.data && reportData.rentTrend.data.length > 0"
      >
        <rent-trend-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
    <b-row id="item-5">
      <b-col
        md="12"
        v-if="
          reportData.rentTrend.data &&
          reportData.rentTrend.data.length > 0 &&
          reportData.prixm2ByPeriode.length > 0
        "
      >
        <rental-yield-trend-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
    <b-row id="item-6">
      <b-col
        md="12"
        v-if="
          reportData.ecosystemPrograms &&
          reportData.ecosystemPrograms.length > 1
        "
      >
        <ecosystem-programs-card :reportData="reportData" v-on="$listeners" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BAvatar } from "bootstrap-vue";

import SquareMetersPriceTypeCard from "./square-meter-price/SquareMetersPriceTypeCard.vue";
import SquareMetersPriceTrendCard from "./square-meter-price/SquareMetersPriceTrendCard.vue";
import RentTrendCard from "./rent/RentTrendCard.vue";
import RentalYieldTrendCard from "./rental-yield-trend/RentalYieldTrendCard.vue";
import EcosystemProgramsCard from "./ecosystem-programs/EcosystemProgramsCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,

    SquareMetersPriceTypeCard,
    SquareMetersPriceTrendCard,
    RentTrendCard,
    RentalYieldTrendCard,
    EcosystemProgramsCard,
  },
  props: {
    reportData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
